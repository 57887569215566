import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CatalogService } from 'src/app/model/catalog-service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css'],
})
export class ServiceListComponent implements OnInit {
  @Input() serviceList: CatalogService[] = [];

  _selectedService: CatalogService;
  @Output() selectedService = new EventEmitter<CatalogService>();

  constructor() {
    this.serviceList = [];
  }

  AddService() {
    var newService = new CatalogService();
    newService.title = '--';
    this.serviceList.push(newService);
    this.selectedService.emit(newService);
  }

  SelectService(service: CatalogService) {
    this._selectedService = service;
    this.selectedService.emit(service);
  }

  DeleteService(indx: number) {
    this.serviceList.splice(indx, 1);
  }

  ngOnInit(): void {}
}
