import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ITreeOptions,
  KEYS,
  TreeComponent,
} from '@circlon/angular-tree-component';
import { noop } from 'rxjs';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { InitParams } from 'src/app/model/init-params';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';

@Component({
  selector: 'app-site-params-json',
  templateUrl: './site-params-json.component.html',
  styleUrls: ['./site-params-json.component.css'],
})
export class SiteParamsJSONComponent implements OnInit {
  _site: Site;
  allowEdit: boolean;

  @Input()
  set site(val: Site) {
    this._site = val;
    this.Load();
  }

  get site(): Site {
    return this._site;
  }

  params: any;

  constructor(
    private main: CatalogMainService,
    private config: WegaCatalogConfigService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.allowEdit = this.config.allowEditSite && this.site.allowEdit;
  }

  async Load() {
    var url = await this.config.GetUrlInit(this.site.code);
    var data = await this.main.AjaxGet(url);
    if (!data) {
      data = new InitParams();
    }

    this.params = JSON.stringify(data, null, 2);
  }

  async Save() {
    try {
      var url = await this.config.GetUrlInit(this.site.code);
      await this.main.AjaxPost(url, JSON.parse(this.params));

      this.snackBar.open('Конфигурация успешно сохранена', null, {
        duration: 5000,
      });
    } catch (e) {
      this.snackBar.open('Ошибка сохранения, см. консоль', null, {
        duration: 3000,
      });

      console.log(e);
    }
  }
}
