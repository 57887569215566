import { Component, Input, OnInit } from '@angular/core';
import { CatalogService } from 'src/app/model/catalog-service';
import { GeoExtent } from '../../model/catalog-service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css'],
})
export class ServiceDetailsComponent implements OnInit {
  @Input() service: CatalogService;

  constructor() {}

  ngOnInit(): void {}

  AddExtent() {
    this.service.extent = new GeoExtent();
  }
}
