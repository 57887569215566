import { getSupportedInputTypes } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-key-value-list',
  templateUrl: './key-value-list.component.html',
  styleUrls: ['./key-value-list.component.css'],
})
export class KeyValueListComponent implements OnInit {
  @Input('itemsList')
  public itemsList: any = {};

  @Output('itemsListChange') modelChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  Add() {
    if (!this.itemsList) {
      this.itemsList = {};
    }
    this.itemsList['--'] = '--';
  }

  Delete(keyName) {
    delete this.itemsList[keyName];
  }

  ChangeValue(pKey: string, pValue: string, pOldName: string = null) {
    if (pOldName) {
      delete this.itemsList[pOldName];
    }
    this.itemsList[pKey] = pValue;
    this.modelChange.emit(this.itemsList);
  }
}
