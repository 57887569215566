import { Injectable } from '@angular/core';
import { CatalogNode } from './catalog-node';
import { CatalogResource } from './catalog-resource';
import { InitParams } from './init-params';
import { Site } from './site';
import { WegaCatalogConfigService } from './wc-config.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogMainService {
  // Список просмотренных ресурсов (чтобы по кнопке "сохранить") сохранить их все
  private resourceList: { [resourceID: string]: CatalogResource } = {};

  constructor(private config: WegaCatalogConfigService) {}

  async GetSitesList(): Promise<Site[]> {
    var url = await this.config.GetUrlSitesList();
    var list = await this.AjaxGet<Site[]>(url);
    return list;
  }

  async GetSite(siteName: string): Promise<Site> {
    var url = await this.config.GetUrlSite(siteName);
    var site = await this.AjaxGet<Site>(url);
    return site;
  }

  async AddSite(): Promise<Site> {
    var newSite = new Site();
    return newSite;
  }

  async UpdateSite(site: Site): Promise<Site> {
    var url = await this.config.GetUrlSite(site.code);
    var site = await this.AjaxPost<Site, Site>(url, site);
    return site;
  }

  async DeleteSite(siteCode: string): Promise<any> {
    var url = await this.config.GetUrlSite(siteCode);
    var newSite = await this.AjaxDelete(url);
    return newSite;
  }

  async GetResourceList(site: Site): Promise<CatalogNode[]> {
    var url = await this.config.GetUrlResourceList(site.code);
    var list = await this.AjaxGet<CatalogNode[]>(url);
    if (!list) {
      list = [];
    }
    return list;
  }

  async UpdateResourceList(
    siteCode: string,
    nodesList: CatalogNode[]
  ): Promise<CatalogNode[]> {
    var url = await this.config.GetUrlResourceList(siteCode);
    //this.DeleteResourcesFromNodes(nodesList);
    var nodesList = await this.AjaxPost<CatalogNode[], CatalogNode[]>(
      url,
      nodesList
    );
    return nodesList;
  }

  DeleteResourcesFromNodes(nodesList: CatalogNode[]) {
    if (!nodesList || !Array.isArray(nodesList)) {
      return;
    }

    for (var node of nodesList) {
      if (node.resource) {
        this.CacheResource(node.id, node.resource);
      }
      node.resource = null;
      this.DeleteResourcesFromNodes(node.children);
    }
  }

  CacheResource(resourceid: string, resourceData: CatalogResource) {
    var cachedResource = this.resourceList[resourceid];
    if (!cachedResource) {
      this.resourceList[resourceid] = resourceData;
    }
  }

  async GetResource(site: Site, resourceid: string): Promise<CatalogResource> {
    var cachedResource = this.resourceList[resourceid];

    /// Если ресурс закэширован - возвращаем из кэша
    if (!cachedResource) {
      var url = await this.config.GetUrlResource(site.code, resourceid);
      this.resourceList[resourceid] = await this.AjaxGet<CatalogResource>(url);
    }

    return this.resourceList[resourceid];
  }

  async UpdateAllResources() {
    for (const resourceID in this.resourceList) {
      var resourceJson = this.resourceList[resourceID];
      var resourceNotEmpty = resourceJson && resourceJson.service;
      if (resourceNotEmpty) {
        await this.UpdateResource('any', resourceID, resourceJson);
      }
    }
    this.resourceList = {};
  }

  async UpdateResource(
    siteCode: string,
    resourceid: string,
    resourceMetadata: CatalogResource
  ): Promise<CatalogResource> {
    var url = await this.config.GetUrlResource(siteCode, resourceid);
    var data = await this.AjaxPost<CatalogResource, CatalogResource>(
      url,
      resourceMetadata
    );
    return data;
  }

  async GetSiteInit(siteCode: string): Promise<InitParams> {
    var url = await this.config.GetUrlInit(siteCode);
    var data = await this.AjaxGet<InitParams>(url);
    if (!data) {
      data = new InitParams();
    }
    return data;
  }

  async UpdateSiteInit(
    siteCode: string,
    data: InitParams
  ): Promise<InitParams> {
    var url = await this.config.GetUrlInit(siteCode);
    var data = await this.AjaxPost<InitParams, InitParams>(url, data);
    if (!data) {
      data = new InitParams();
    }
    return data;
  }

  async SaveConfig() {
    var url = this.config.GetUrlConfig();
    var config = await this.AjaxPost<WegaCatalogConfigService, WegaCatalogConfigService>(
      url,
      this.config
    );
    return config;
  }

  async AjaxGet<T>(url: string): Promise<T> {
    var query = await fetch(url, { method: 'GET', mode: 'cors' });
    var ans = await query.json();
    var ansClass = ans as T;
    return ansClass;
  }

  async AjaxPost<Tin, Tout>(url: string, data: Tin): Promise<Tout> {
    var query = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    });
    var ans = await query.json();
    var ansClass = ans as Tout;
    return ansClass;
  }

  async AjaxDelete(url: string): Promise<any> {
    var query = await fetch(url, { method: 'DELETE', mode: 'cors' });
    return true;
  }
}
