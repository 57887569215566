<p>Настройка сайта</p>

<span *ngIf="allowEdit">
    <button (click)="SaveSiteMetadata()">Сохранить</button>
</span>

<table *ngIf="site">
    <tr>
        <td>Code</td>
        <td>
            {{site.code}}
        </td>
    </tr>
    <tr>
        <td>Название</td>
        <td>
            <inputx type="text" [(ngModel)]="site.name"></inputx>
        </td>
    </tr>
    <tr>
        <td>Описание</td>
        <td>
            <textarea type="text" [(ngModel)]="site.Description">
            </textarea>
        </td>
    </tr>
    <tr>
        <td>UrlList</td>
        <td>
            <app-strings-list [(itemsList)]="site.urlList"></app-strings-list>
        </td>
    </tr>

    <tr>
        <td>Разрешения</td>
        <td>
            <inputx type="checkbox" [(ngModel)]="site.isPublic"></inputx> (Public) Доступен без авторизации<br>
            <inputx type="checkbox" [(ngModel)]="site.allowEdit"></inputx> Можно редактировать (вообще)<br>

            Пользователи (чтение)<br>
            <app-strings-list [(itemsList)]="site.readUsers"></app-strings-list>
            <hr>

            Пользователи (администратор сайта)<br>
            <app-strings-list [(itemsList)]="site.adminUsers"></app-strings-list>
            <hr>

        </td>
    </tr>

</table>
