import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { Site } from 'src/app/model/site';

@Component({
  selector: 'app-site-metadata',
  templateUrl: './site-metadata.component.html',
  styleUrls: ['./site-metadata.component.css'],
})
export class SiteMetadataComponent implements OnInit {
  allowEdit: boolean = true;

  @Input()
  site: Site;

  constructor(
    private main: CatalogMainService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  SaveSiteMetadata() {
    this.main.UpdateSite(this.site);

    this.snackBar.open('Сайт обновлен', null, {
      duration: 5000,
    });
  }
}
