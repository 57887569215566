<table>
    <tr *ngFor="let item of itemsList | keyvalue">
        <td> <button (click)="Delete(item.key)">X</button></td>
        <td> <input [ngModel]="item.key" (change)="ChangeValue($event.target.value, item.value, item.key)" size=10></td>
        <td> <input [ngModel]="item.value" (change)="ChangeValue(item.key, $event.target.value)" size=10> </td>
    </tr>

    <tr>
        <td></td>
        <td> <button (click)="Add()">Add</button> </td>
    </tr>
</table>
