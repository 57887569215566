<h1>Параметры инициализации</h1>
<button (click)="ReloadConfig()">Перезагрузить</button>
<button (click)="SaveConfig()">Сохранить</button>

{{config.allowDeleteSite}}

<table *ngIf="config">
    <tr>
        <td>Разрешения</td>
        <td>
            <inputx type="checkbox" [(ngModel)]="config.allowAddSite"></inputx> Добавление сайтов<br>
            <inputx type="checkbox" [(ngModel)]="config.allowEditSite"></inputx> Редактирование сайта<br>
            <inputx type="checkbox" [(ngModel)]="config.allowDeleteSite"></inputx> Удаление сайтов<br>
        </td>
    </tr>
    <tr>
        <td>Пользователи-админы</td>
        <td>
            <app-strings-list [(itemsList)]="config.adminUsers"></app-strings-list>
        </td>
    </tr>
</table>
