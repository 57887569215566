import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { CatalogResource } from 'src/app/model/catalog-resource';
import { CatalogService } from 'src/app/model/catalog-service';
import { FieldConfig } from 'src/app/model/field-description';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css'],
})
export class ResourceComponent implements OnInit {
  @Input()
  public site: Site;

  public showLayerDescription: boolean = false;
  public showFieldDescription: boolean = false;

  private _node: CatalogNode = new CatalogNode();

  allowEdit: boolean;

  @Input() set node(value: CatalogNode) {
    this._node = value;
    this.showLayerDescription = false;
    this.showFieldDescription = false;
    this.selectedService = null;
    this.selectedAttribute = null;

    this.LoadResource();
  }

  get node() {
    return this._node;
  }
  //  public node: CatalogNode = new CatalogNode();

  serviceList: CatalogService[];
  selectedService: CatalogService;
  selectedAttribute: FieldConfig;

  constructor(
    private main: CatalogMainService,
    private config: WegaCatalogConfigService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.LoadResource();
    this.allowEdit = this.config.allowEditSite && this.site.allowEdit;
  }

  //// Импорт

  onSelectService(service: CatalogService) {
    this.showLayerDescription = true;
    this.selectedService = service;
    this.selectedAttribute = null;
  }

  onSelectField(flddesc: FieldConfig) {
    this.selectedAttribute = flddesc;
    this.showFieldDescription = true;
  }

  async LoadResource(pForce: boolean = false) {
    var resourceIsLoaded = this.node.resource && this.node.resource.service;
    if (!resourceIsLoaded || pForce) {
      var resourceJsondescription = await this.main.GetResource(
        this.site,
        this.node.id
      );

      if (resourceJsondescription && resourceJsondescription.service) {
        this.node.resource = resourceJsondescription;
      }

      if (!this.node.resource) {
        this.node.resource = new CatalogResource();
      }

      CatalogNode.ValidateFix(this.node);
    }

    this.AfterResourceLoaded();
  }

  AfterResourceLoaded() {
    if (!this.node.resource.extendFields) {
      this.node.resource.extendFields = [];
    }

    if (this.node.resource.service.length == 1) {
      var onlyOneService = this.node.resource.service[0];
      this.onSelectService(onlyOneService);
    }
  }
}
