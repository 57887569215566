import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { Site } from 'src/app/model/site';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css'],
})
export class SiteComponent implements OnInit {
  siteCode: string;
  site: Site;

  constructor(private route: ActivatedRoute, private main: CatalogMainService) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.siteCode = params.get('site');
      this.Reload();
    });
  }
  async Reload() {
    this.site = await this.main.GetSite(this.siteCode);
  }

  ngOnInit(): void {
    // this.site = this.route.snapshot.paramMap.get('site');
  }
}
