<table>
    <tr *ngFor="let item of itemsList; let i = index;  trackBy: this.trackByFn">
        <td> <button (click)="Delete(i)">X</button></td>
        <td>
            <div-input [value]="itemsList[i]" (valueChange)="ItemChange(i, $event)" size=10 [edit]="true">
            </div-input>
        </td>
    </tr>

    <tr>
        <td></td>
        <td> <button (click)="Add()">Add</button> </td>
    </tr>
</table>
