Настройка карты ({{node.name}}):
<table>
    <tbody>
        <tr>
            <td>ID</td>
            <td>{{node.id}}</td>
        </tr>
        <tr>
            <td>Тип узла:</td>
            <td><select [(ngModel)]="node.type">
                    <option value="folder">Папка</option>
                    <option value="module">Модуль</option>
                    <option value="resource">Карта</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>Name</td>
            <td>
                <div-input [(value)]="node.name" edit="true"></div-input>
            </td>
        </tr>
        <tr>
            <td>Title</td>
            <td>
                <div-input [(value)]="node.resource.title" edit="true"></div-input>
            </td>
        </tr>
    </tbody>


    <tbody *ngIf="node.type=='resource'">
        <tr>
            <td>longTitle:</td>
            <td>
                <div-input [(value)]="node.resource.longTitle" edit="true"></div-input>
            </td>
        </tr>
        <tr>
            <td>Источник ресурса (карты)</td>
            <td>
                <div-input [(value)]="node.resource.origin" edit="true"></div-input>
            </td>
        </tr>
        <tr>
            <td>Актуальность ресурса</td>
            <td>
                <div-input [(value)]="node.resource.relevance" edit="true"></div-input>
            </td>
        </tr>

        <tr>
            <td>Description</td>
            <td>
                <div-input [(value)]="node.resource.description" edit="true"></div-input>
            </td>
        </tr>


        <tr>
            <td>Атрибуты для названия</td>
            <td>
                <app-strings-list [(itemsList)]="node.resource.featureDisplayAttrs"></app-strings-list>
            </td>
        </tr>

        <tr>
            <td>Скрытые атрибуты (не выводить в запросе)</td>
            <td>
                <app-strings-list [(itemsList)]="node.resource.forbiddenFields"></app-strings-list>
            </td>
        </tr>

        <tr>
            <td alt="Атрибуты, в которых содержится html-форматированный текст. Текст нужно выводить с форматированием (вставляется в innerHTML)">Форматированные атрибуты</td>
            <td>
                <app-strings-list [(itemsList)]="node.resource.formattedFields"></app-strings-list>
            </td>
        </tr>

        <tr>
            <td>Флаги</td>
            <td>
                <input type="checkbox" [(ngModel)]="node.resource.allowDownloadSelected">Скачивать выделенное <br>
                <input type="checkbox" [(ngModel)]="node.resource.filterEnabled">Показывать фильтр на слое <br>
                <input type="checkbox" [(ngModel)]="node.resource.hidden">Скрывать в каталоге<br>
                <input type="checkbox" [(ngModel)]="node.resource.visibility">Включен по умолчанию<br>
                <input type="checkbox" [(ngModel)]="node.resource.pinned">Всегда поверх прочих карт<br>
                <input type="checkbox" [(ngModel)]="node.resource.editAllowed">Разрешено редактирование<br>
                <input type="checkbox" [(ngModel)]="node.resource.sortFields">Сортировка атрибутивных полей<br>
                <input type="checkbox" [(ngModel)]="node.resource.disabled">Неактивен<br>
                <input type="checkbox" [(ngModel)]="node.resource.hideEmptyFields">Прятать пустые поля<br>
                <input type="checkbox" [(ngModel)]="node.resource.hideEmptyLegends">Прятать пустые легенды<br>
                <input type="checkbox" [(ngModel)]="node.resource.onlyLastTitles">Листовые названия слоев<br>
                <input type="checkbox" [(ngModel)]="node.resource.fuzzyLayersGrouping">Подбор списка слоев через indexOf<br>
                <input type="checkbox" [(ngModel)]="node.resource.switchChildrenOnly">Служит для переключения дочерних узлов<br>
            </td>
        </tr>

        <tr>
          <td>Extent</td>
          <td *ngIf="node.resource.extent">
              SRS <input [(ngModel)]="node.resource.extent.srs"><br>
              MaxY <input [(ngModel)]="node.resource.extent.ymax" size=7><br>
              X <input [(ngModel)]="node.resource.extent.xmin" size=7> -- <input [(ngModel)]="node.resource.extent.xmax" size=7><br>
              MinY <input [(ngModel)]="node.resource.extent.ymin" size=7><br>
          </td>
          <td *ngIf="!node.resource.extent">
              <button (click)="AddExtent()">Add</button>
          </td>
      </tr>

        <tr>
          <td>Файловые легенды (для карты)</td>
          <td>
              <table border="1" *ngIf="node.resource.mapFileLegends?.length">
                  <tr>
                      <td></td>
                      <td>Название легенды</td>
                      <td>Ссылки на легенды</td>
                  </tr>

                  <tr *ngFor="let legend of node.resource.mapFileLegends; let index=index">
                      <td><button (click)="node.resource.mapFileLegends.splice(index,1)">X</button></td>
                      <td>
                          <div-input [(value)]="legend.name" edit="true"></div-input>
                      </td>
                      <td>
                          <app-strings-list [(itemsList)]="legend.legends"></app-strings-list>
                      </td>
                  </tr>
                  <tr>
                      <td colspan="2">
                          <button (click)="node.resource.mapFileLegends.push({ name: '' , legends: [] })">Добавить легенду карты</button>
                      </td>
                  </tr>
              </table>
              <button *ngIf="!node.resource.mapFileLegends?.length"
                  (click)="node.resource.mapFileLegends=[{ name: '' , legends: [] }]">Создать легенду карты</button>

          </td>
        </tr>

        <tr>
          <td>Файловые легенды (для слоев)</td>
          <td>
              <table border="1" *ngIf="node.resource.fileLegends?.length">
                  <tr>
                      <td></td>
                      <td>Ключ слоя</td>
                      <td>Заголовок легенды</td>
                      <td>Ссылки на легенды</td>
                  </tr>

                  <tr *ngFor="let layer of node.resource.fileLegends; let index=index">
                      <td><button (click)="node.resource.fileLegends.splice(index,1)">X</button></td>
                      <td>
                          <div-input [(value)]="layer.name" edit="true"></div-input>
                      </td>
                      <td>
                        <div-input [(value)]="layer.name" edit="true"></div-input>
                      </td>
                      <td>
                          <app-strings-list [(itemsList)]="layer.legends"></app-strings-list>
                      </td>
                  </tr>
                  <tr>
                      <td colspan="2">
                          <button (click)="node.resource.fileLegends.push({ name: '', title: '', legends: [] })">Добавить легенду</button>
                      </td>
                  </tr>
              </table>
              <button *ngIf="!node.resource.fileLegends?.length" (click)="node.resource.fileLegends=[{ name: '', title: '', legends: [] }]">Создать легенду</button>
            </td>
      </tr>

    </tbody>

    <tbody *ngIf="node.type=='module'">
        <tr>
            <td>Тип модуля</td>
            <td><select [(ngModel)]="node.moduleType">
                    <option value="FileCatalog">FileCatalog</option>
                    <option value="RasterDBCatalog">RasterDBCatalog</option>
                    <option value="ArcgisCatalog">ArcgisCatalog</option>
                    <option value="GisPackageCatalog">GisPackageCatalog</option>
                    <option value="ESOCatalog">ESOCatalog</option>
                    <option value="TemplateCatalog">TemplateCatalog</option>
                    <option value="CSW">CSW Metadata Server</option>
                    <option value="WMS">WMS Server Layers</option>
                    <option value="WFS">WFS Server Layers</option>
                </select> </td>
        </tr>
        <tr>
            <td>URL</td>
            <td>
                <div-input [(value)]="node.moduleUrl" edit="true"></div-input>
            </td>
        </tr>
    </tbody>
</table>
