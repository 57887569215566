<h1>Параметры инициализации</h1>
<span *ngIf="allowEdit">
    <button (click)="SaveInitParams()">Сохранить</button>
</span>

<span *ngIf="!allowEdit">
  Редактирование запрещено
</span>


<br>
<table>
    <tr>
        <td>
            <table *ngIf="initParams">
                <tr>
                    <td class="GroupTitle" colspan="2">Кастомизация сайта</td>
                </tr>
                <tr>
                    <td>Заголовок сайта (PageTitle)</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.PageTitle"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>Описание сайта (About)</td>
                    <td>
                        <inputx type="textarea" [(ngModel)]="initParams.AboutText "></inputx>
                    </td>
                </tr>

                <tr>
                    <td>Логотип сайта (URL)</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.LogoImageURL"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>Ссылка логотипа сайта (при щелчке по лого)</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.LogoLinkURL"></inputx>
                    </td>
                </tr>

                <tr>
                    <td class="GroupTitle" colspan="2">Технические метаданные</td>
                </tr>

                <tr>
                  <td>InitDefaultPath</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.InitDefaultPath"></inputx>
                  </td>
                </tr>

                <tr>
                  <td>InitOverridePath</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.InitOverridePath"></inputx>
                  </td>
                </tr>

                <tr>
                    <td>RootCatalogUrl</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.RootCatalogUrl"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>FiltersUrl</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.FiltersUrl"></inputx>
                </tr>

                <tr>
                  <td>PrintProvider</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.PrintProvider"></inputx>
              </tr>

                <tr>
                  <td>CorsScript</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.CorsScript"></inputx>
              </tr>

                <tr>
                    <td>InitJsonUrl</td>
                    <td>
                        <inputx type="text" [(ngModel)]="initParams.InitJsonUrl"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>Дополнительные CSS</td>
                    <td>
                        <app-strings-list [(itemsList)]="initParams.ArbitraryCss"></app-strings-list>
                    </td>
                </tr>

                <tr>
                    <td>Дополнительные JS</td>
                    <td>
                        <app-strings-list [(itemsList)]="initParams.ArbitraryJs"></app-strings-list>
                    </td>
                </tr>

                <tr>
                  <td>HiddenCatalog</td>
                  <td>
                      <app-strings-list [(itemsList)]="initParams.HiddenCatalog"></app-strings-list>
                  </td>
                </tr>

                <tr>
                  <td>HiddenResources</td>
                  <td>
                      <app-strings-list [(itemsList)]="initParams.HiddenResources"></app-strings-list>
                  </td>
                </tr>

                <tr>
                    <td>Запретить сообщения об отладке</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.SupressLog"></inputx> SupressLog <br>
                    </td>
                </tr>

                <tr>
                  <td>IndicateLayerDataStatus</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.IndicateLayerDataStatus"></inputx> IndicateLayerDataStatus <br>
                  </td>
                </tr>

                <tr>
                  <td>EnableGlobeRendererButton</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.EnableGlobeRendererButton"></inputx> EnableGlobeRendererButton <br>
                  </td>
                </tr>

                <tr>
                  <td>EnableAlternateRendererButton</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.EnableAlternateRendererButton"></inputx> EnableAlternateRendererButton <br>
                  </td>
                </tr>

                <tr>
                  <td>EnableLanguageSwitch</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.EnableLanguageSwitch"></inputx> EnableLanguageSwitch <br>
                  </td>
                </tr>

                <tr>
                  <td>DefaultLanguage</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.DefaultLanguage"></inputx>
                  </td>
                </tr>

                <tr>
                    <td>EnableBasemapSelection</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.EnableBasemapSelection"></inputx> EnableBasemapSelection <br>
                    </td>
                </tr>

                <tr>
                  <td>CustomBasemaps</td>
                  <td>
                      <table border="1" *ngIf="initParams.CustomBasemaps?.length">
                          <tr>
                              <td></td>
                              <td>id</td>
                              <td>title</td>
                              <td>thumbnail</td>
                              <td>proxy</td>
                              <td>layers</td>
                          </tr>

                          <tr *ngFor=" let basemapInfo of initParams.CustomBasemaps; let index=index">
                              <td><button (click)="initParams.CustomBasemaps.splice(index,1)">X</button></td>
                              <td>
                                  <div-input [(value)]="basemapInfo.id" edit="true"></div-input>
                              </td>
                              <td>
                                  <div-input [(value)]="basemapInfo.title" edit="true"></div-input>
                              </td>
                              <td>
                                  <div-input [(value)]="basemapInfo.thumbnail" edit="true"></div-input>
                              </td>
                              <td>
                                  <div-input [(value)]="basemapInfo.proxy" edit="true"></div-input>
                              </td>
                              <td>
                                <table border="1">
                                    <tr>
                                        <td></td>
                                        <td>url</td>
                                        <td>checkUrl</td>
                                    </tr>

                                    <tr *ngFor=" let basemapLayerInfo of basemapInfo.layers; let jndex=jndex">
                                        <td><button (click)="basemapLayerInfo.splice(jndex,1)">X</button></td>
                                        <td>
                                            <div-input [(value)]="basemapLayerInfo.url" edit="true"></div-input>
                                        </td>
                                        <td>
                                            <div-input [(value)]="basemapLayerInfo.checkUrl" edit="true"></div-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <button (click)="basemapInfo.layers.push({ url: '' , checkUrl: ''})">Добавить
                                                слой</button>
                                        </td>
                                    </tr>
                                </table>
                                <button *ngIf="!basemapInfo.layers?.length"
                                    (click)="basemapInfo.layers= [{ url: '' , checkUrl: '' }]">Создать список слоев подложки</button>

                              </td>
                          </tr>
                          <tr>
                              <td colspan="2">
                                  <button (click)="initParams.CustomBasemaps.push({ id: '' , title: '', thumbnail:'', proxy: '', layers: []})">Добавить
                                      подложку</button>
                              </td>
                          </tr>
                      </table>
                      <button *ngIf="!initParams.CustomBasemaps?.length"
                          (click)="initParams.CustomBasemaps= [{ id: '' , title: '', thumbnail:'', proxy: '', layers: [] }]">Создать список подложек</button>

                  </td>
               </tr>

              <tr>
                <td>
                  Список базовых карт для пользователя
                </td>
                <td>
                  <app-strings-list [(itemsList)]="initParams.AllowedBaseMaps"></app-strings-list>
                </td>
              </tr>

              <tr>
                <td>
                  Стартовый набор карт
                </td>
                <td>
                  <button *ngIf="initParams.StartupMaps" (click)="TurnoffStartupMaps()">X</button>
                  <button (click)="ShowSelectNode()">Add</button>
                  <tree-root #treeStartupMaps [nodes]="initParams.StartupMaps" [options]="treeStartupMapsOptions">
                  </tree-root>
                </td>
              </tr>

              <tr>
                <td>EnableContextMenu</td>
                <td>
                    <inputx [(ngModel)]="initParams.EnableContextMenu"></inputx>
                </td>
              </tr>

              <tr>
                  <td>Запретить сообщения об отладке</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.SupressLog"></inputx> SupressLog <br>
                  </td>
              </tr>

                <tr>
                    <td class="GroupTitle" colspan="2">Панели</td>
                </tr>

                <tr>
                    <td>Прятать левую панель (кроме окна результатов выборки)</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.HideLeftPanelExceptInfo"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>Показывать панель пространственного фильтра</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowSpatialFilter"></inputx>
                    </td>
                </tr>

                <tr>
                  <td>ShowAnalysis</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.ShowAnalysis"></inputx>
                  </td>
                </tr>

                <tr>
                    <td>Показывать каталог</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowCatalogs"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>ShowLayersList</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowLayersList"></inputx>
                    </td>
                </tr>

                <tr>
                  <td>ShowLegend</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.ShowLegend"></inputx>
                  </td>
                </tr>

                <tr>
                  <td>ShowMetadata</td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.ShowMetadata"></inputx>
                  </td>
                </tr>

                <tr>
                  <td>ShowView<br></td>
                  <td>
                      <inputx type="checkbox" [(ngModel)]="initParams.ShowView"> </inputx>
                  </td>
              </tr>

              <tr>
                <td>ShowFilter<br></td>
                <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.ShowFilter"> </inputx>
                </td>
              </tr>

                <tr>
                    <td>Показывать окно поиска<br></td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.UseSearch"> </inputx>
                    </td>
                </tr>

                <tr>
                    <td>Ширина левой панели (в пикселях)</td>
                    <td>
                        <inputx [(ngModel)]="initParams.PanelWidth"></inputx>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td></td>
                </tr>

                <tr>
                  <td>LogoImageURL</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.LogoImageURL"></inputx>   </td>
                </tr>

                <tr>
                  <td>LogoLinkURL</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.LogoLinkURL"></inputx>
                    </td>
                </tr>

                <tr>
                  <td>SplashScreenEnabled</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.SplashScreenEnabled"> </inputx>   </td>
                </tr>

                <tr>
                  <td>SplashScreenLogo</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.SplashScreenLogo"></inputx>   </td>
                </tr>

                <tr>
                  <td>SplashScreenTitle</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.SplashScreenTitle"></inputx>   </td>
                </tr>

                <tr>
                  <td>SplashScreenVideoURL</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.SplashScreenVideoURL"></inputx>   </td>
                </tr>

                <tr>
                  <td>SplashScreenDelay</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.SplashScreenDelay"></inputx>   </td>
                </tr>

                <tr>
                    <td></td>
                    <td></td>
                </tr>

                <tr>
                  <td>ProxyUrl</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.ProxyUrl"></inputx>   </td>
                </tr>

                <tr>
                  <td>EncodeProxyRequest</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.EncodeProxyRequest"> </inputx>   </td>
                </tr>

                <tr>
                  <td>SearchResultsPageSize</td>
                  <td>
                      <inputx type="text" [(ngModel)]="initParams.SearchResultsPageSize"></inputx>   </td>
                </tr>

                <tr>
                  <td>DisabledSearchEngines</td>
                  <td>
                    <app-strings-list [(itemsList)]="initParams.DisabledSearchEngines"></app-strings-list>
                  </td>
                </tr>

                <tr>
                  <td>HideSearchButtonForQueryResults</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.HideSearchButtonForQueryResults"> </inputx>
                  </td>
                </tr>

                <tr>
                  <td>EnableProjections</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.EnableProjections"> </inputx>
                  </td>
                </tr>

                <tr>
                  <td>ShowSearch</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.ShowSearch"> </inputx>
                  </td>
                </tr>

                <tr>
                  <td>EnableSwiping</td>
                  <td>
                    <inputx type="checkbox" [(ngModel)]="initParams.EnableSwiping"> </inputx>
                  </td>
                </tr>

                <tr>
                  <td>DbfServiceUrl</td>
                  <td>
                    <inputx type="text" [(ngModel)]="initParams.DbfServiceUrl"></inputx>
                  </td>
                </tr>

                <tr>
                  <td>StartupMapIdList</td>
                  <td>
                    <app-strings-list [(itemsList)]="initParams.StartupMapIdList"></app-strings-list>
                  </td>
                </tr>

                <tr>
                  <td class="GroupTitle" colspan="2"> Кнопки </td>
                </tr>

                <tr>
                    <td>Показать кнопку</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowLocateButton"></inputx> Текущее местоположение <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowProfileButton"></inputx> Профиль <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowAboutButton"></inputx> О ресурсе <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.UseExportButton"></inputx> Экспорт <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.DownloadMultipleFilesAsZip"></inputx> Скачать набор файлов как ZIP <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowDashboardButton"></inputx> Дэшборд <br>
                    </td>
                </tr>

                <tr>
                    <td class="GroupTitle" colspan="2">Подстройка блока карты</td>
                </tr>

                <tr>
                    <td>Показывать на карте</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.AllowChangeScale"></inputx> шкалу<br>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowOverviewMap"> </inputx>мини-карту (внизу справа) <br>
                        <inputx type="checkbox" [(ngModel)]="initParams.TrackCurrentPosition"> </inputx> координаты под курсором<br>
                        <inputx type="checkbox" [(ngModel)]="initParams.UseScalebar"> </inputx>строку Масштабную <br>
                    </td>
                </tr>

                <tr>
                    <td>Поведение карты</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.QueryOnlyOnContextMenu"></inputx>Запрос только по контекстному меню<br>
                        <inputx type="checkbox" [(ngModel)]="initParams.EnableSelectWhilePan"></inputx>Разрешить выделять щелчком по карте в режиме Pan<br>
                        <inputx type="checkbox" [(ngModel)]="initParams.HideEmptyAttributeValues"></inputx>Скрывать пустые атрибуты (в окне результатов выборки) <br>
                    </td>
                </tr>
                <tr>
                    <td>При наведении мыши</td>
                    <td>
                        <inputx type="checkbox" [(ngModel)]="initParams.ShowQueryResultOnMapHover"></inputx> Показывать в фоне данные о точке при наведении мыши (без необходимости щелчка)<br>
                        Интервал для запрос по наведению (QueryHoverMapInterval)<br><inputx [(ngModel)]="initParams.QueryHoverMapInterval"></inputx>
                    </td>
                </tr>

                <tr>
                    <td class="GroupTitle" colspan="2"> Набор слоёв </td>
                </tr>

                <tr>
                    <td>Базовая карта по-умолчанию (BasemapId)</td>
                    <td>
                        <inputx [(ngModel)]="initParams.BasemapId"></inputx>
                    </td>
                </tr>

                <tr>
                  <td>EmergencyBasemapId</td>
                  <td>
                      <inputx [(ngModel)]="initParams.EmergencyBasemapId"></inputx>
                  </td>
              </tr>

                <tr>
                    <td>Базовая карта (custom)</td>
                    <td>
                        <button *ngIf="initParams.UseAsBase" (click)='initParams.UseAsBase = null' title="удалить значение">X</button>
                        <button *ngIf="!initParams.UseAsBase" (click)='initParams.UseAsBase={"url":"", "hidden": true}' title="Задать значение">[+]</button>
                        <div *ngIf="initParams.UseAsBase">
                            URL: <div-input [(value)]="initParams.UseAsBase.url" [edit]="true"></div-input><br>
                            <input type="checkbox" [(ngModel)]="initParams.UseAsBase.hidden"> Скрыть слой
                        </div>
                    </td>
                </tr>

                <tr>
                  <td>Extent</td>
                  <td>
                    <ng-container *ngIf="initParams.Extent">
                      MaxY <input [(ngModel)]="initParams.Extent.ymax" size=7><br>
                      X <input [(ngModel)]="initParams.Extent.xmin" size=7> -- <input [(ngModel)]="initParams.Extent.xmax" size=7><br>
                      MinY <input [(ngModel)]="initParams.Extent.ymin" size=7><br>
                      <button (click)="RemoveExtent()">Delete</button>
                    </ng-container>
                    <ng-container *ngIf="!initParams.Extent">
                      <button (click)="AddExtent()">Add</button>
                    </ng-container>
                  </td>
              </tr>

                <tr>
                    <td>LoadingAnimationTimeout</td>
                    <td>
                      <inputx [(ngModel)]="initParams.LoadingAnimationTimeout"></inputx>
                    </td>
                </tr>

                <tr>
                    <td>ExcludeResourcesFromSelection</td>
                    <td>
                        <app-strings-list [(itemsList)]="initParams.ExcludeResourcesFromSelection"></app-strings-list>
                    </td>
                </tr>

                <tr>
                  <td>Допустимые типы ресурсов</td>
                  <td>
                      <table border="1" *ngIf="initParams.ResourceTypes?.length">
                          <tr>
                              <td></td>
                              <td>Название</td>
                              <td>Тип</td>
                          </tr>

                          <tr *ngFor=" let typeInfo of initParams.ResourceTypes; let index=index">
                              <td><button (click)="initParams.ResourceTypes.splice(index,1)">X</button></td>
                              <td>
                                  <div-input [(value)]="typeInfo.title" edit="true"></div-input>
                              </td>
                              <td>
                                  <div-input [(value)]="typeInfo.type" edit="true"></div-input>
                              </td>
                          </tr>
                          <tr>
                              <td colspan="2">
                                  <button (click)=" initParams.ResourceTypes.push({ title: '' , type: '' })">Добавить
                                      тип</button>
                              </td>
                          </tr>
                      </table>
                      <button *ngIf="!initParams.ResourceTypes?.length"
                          (click)="initParams.ResourceTypes= [{ title: '', type: '' }]">Создать список типов</button>

                  </td>
                </tr>

                <tr>
                    <td class="GroupTitle" colspan="2"> Стартовый режим карты </td>
                </tr>

                <tr>
                    <td>Исходный ZOOM-level</td>
                    <td>
                        <inputx type="number" [(ngModel)]="initParams.Zoom"></inputx>
                    </td>
                </tr>
                <tr>
                    <td>Исходный центр (координаты)</td>
                    <td>
                        <span *ngIf="!initParams.Center">
                            <button (click)="AddCenterParameter()">Add</button>
                        </span>
                        <span *ngIf="initParams.Center">
                            X: <input type="number" [(ngModel)]="initParams.Center[0]">
                            <br>
                            Y: <input type="number" [(ngModel)]="initParams.Center[1]">
                        </span>
                    </td>
                </tr>

                <tr>
                    <td>Исходный режим карты</td>
                    <td>
                        <select [(ngModel)]="initParams.MapMode">
                            <option value="pan">pan</option>
                            <option value="select">select</option>
                            <option value="zoom_in">zoom_in</option>
                            <option value="zoom_out">zoom_out</option>
                            <option value="measure">measure</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Режим выделения по умолчанию</td>
                    <td>
                        <select [(ngModel)]="initParams.MapSelectionMode">
                            <option value="select_point">select_point</option>
                            <option value="select_extent">select_extent</option>
                            <option value="select_area">select_area</option>
                        </select>
                    </td>
                </tr>

                <tr>
                  <td>Количество результатов запроса, по превышении которого объекты будут выведены в свернутом виде</td>
                  <td>
                    <inputx type="number" [(ngModel)]="initParams.MaxFeaturesToCollapse"></inputx>
                  </td>
                </tr>

                <tr>
                    <td>Режим измерения по умолчанию</td>
                    <td>
                        <select [(ngModel)]="initParams.MapMeasureMode">
                            <option value="measure_length">measure_length</option>
                            <option value="measure_area">measure_area</option>
                        </select>
                    </td>
                </tr>

                <tr>
                  <td>Поисковые модули</td>
                  <td>
                      <table border="1" *ngIf="initParams.SearchEngines?.length">
                          <tr>
                              <td></td>
                              <td>id</td>
                              <td>mode</td>
                              <td>arguments</td>
                          </tr>

                          <tr *ngFor=" let engine of initParams.SearchEngines; let index=index">
                              <td><button (click)="initParams.SearchEngines.splice(index,1)">X</button></td>
                              <td>
                                  <div-input [(value)]="engine.id" edit="true"></div-input>
                              </td>
                              <td>
                                  <div-input [(value)]="engine.mode" edit="true"></div-input>
                              </td>
                              <td>
                                <app-strings-list [(itemsList)]="engine.arguments"></app-strings-list>
                              </td>
                          </tr>
                          <tr>
                              <td colspan="2">
                                  <button (click)=" initParams.SearchEngines.push({ id: '' , mode: 'default', arguments: [] })">Добавить
                                      модуль</button>
                              </td>
                          </tr>
                      </table>
                      <button *ngIf="!initParams.SearchEngines?.length"
                          (click)="initParams.SearchEngines= [{ id: '' , mode: 'default', arguments: [] }]">Создать модуль</button>

                  </td>
              </tr>

              <tr>
                <td>Показать экранные диаграммы</td>
                <td>
                  <inputx type="checkbox" [(ngModel)]="initParams.ShowMapChart"> </inputx>
                </td>
              </tr>


              <tr>
                <td>Параметры экранных диаграмм</td>
                <td>
                    <table border="1" *ngIf="initParams.MapChartParameters?.length">
                        <tr>
                            <td></td>
                            <td>name (название)</td>
                            <td>type (тип: 'pie')</td>
                            <td>url (адрес для считывания данных)</td>
                            <td>path (путь к нужному полю)</td>
                            <td>colors (цвета)</td>
                        </tr>

                        <tr *ngFor=" let chart of initParams.MapChartParameters; let i=index">
                            <td><button (click)="initParams.MapChartParameters.splice(i,1)">X</button></td>
                            <td>
                                <div-input [(value)]="chart.name" edit="true"></div-input>
                            </td>
                            <td>
                                <div-input [(value)]="chart.type" edit="true"></div-input>
                            </td>
                            <td>
                              <div-input [(value)]="chart.url" edit="true"></div-input>
                            </td>
                            <td>
                              <div-input [(value)]="chart.path" edit="true"></div-input>
                            </td>
                            <td>
                                <table border="1" *ngIf="chart.colors?.length">
                                    <tr>
                                        <td></td>
                                        <td>значение</td>
                                        <td>цвет</td>
                                    </tr>
                                    <tr *ngFor=" let color of chart.colors; let j=index">
                                        <td><button (click)="chart.colors.splice(j,1)">X</button></td>
                                        <td>
                                            <div-input [(value)]="color.value" edit="true"></div-input>
                                        </td>
                                        <td>
                                            <div-input [(value)]="color.htmlColor" edit="true"></div-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <button (click)="chart.colors.push({ value: '' , htmlColor: '' })">Добавить
                                                цвет</button>
                                        </td>
                                    </tr>
                                </table>
                                <button *ngIf="!chart.colors?.length"
                                    (click)="chart.colors= [{ value: '' , htmlColor: '' }]">Создать цвет
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button (click)=" initParams.MapChartParameters.push({ name: '' , type: 'pie', url: '', path: '' })">Добавить
                                    диаграмму</button>
                            </td>
                        </tr>
                    </table>
                    <button *ngIf="!initParams.MapChartParameters?.length"
                        (click)="initParams.MapChartParameters= [{ name: '' , type: 'pie', url: '', path: '' }]">Создать диаграмму
                    </button>
                </td>
              </tr>
            </table>

        </td>
        <td>
            <app-select-resource *ngIf="selectResource" (onSelectNode)="SelectNode($event)"></app-select-resource>
        </td>
    </tr>
</table>
