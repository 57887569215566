import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';

@Component({
  selector: 'app-catalog-configuration',
  templateUrl: './catalog-configuration.component.html',
  styleUrls: ['./catalog-configuration.component.css'],
})
export class CatalogConfigurationComponent implements OnInit {
  constructor(
    public config: WegaCatalogConfigService,
    private main: CatalogMainService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ReloadConfig() {
    this.config.LoadConfig();
  }

  SaveConfig() {
    this.main.SaveConfig();

    this.snackBar.open('Конфигурация сохранена', null, {
      duration: 5000,
    });
  }
}
