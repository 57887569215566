export class Site {
  public code: string;
  public name: string;

  public Description: string;

  public urlList: string[];
  public lastChangeDate: Date;
  public isPublic: boolean;

  public allowEdit: boolean;

  public readUsers: string[];
  public adminUsers: string[];

  public tmpCode: string;
}
