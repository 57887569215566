import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-strings-list',
  templateUrl: './strings-list.component.html',
  styleUrls: ['./strings-list.component.css'],
})
export class StringsListComponent implements OnInit {
  @Input()
  public itemsList: string[];

  @Output()
  itemsListChange: EventEmitter<string[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  Add() {
    if (!this.itemsList || !Array.isArray(this.itemsList)) {
      this.itemsList = [];
    }
    this.itemsList.push('-');
    this.itemsListChange.emit(this.itemsList);
  }

  Delete(indx: number) {
    this.itemsList.splice(indx, 1);
    this.itemsListChange.emit(this.itemsList);
  }

  ItemChange(i, value) {
    this.itemsList[i] = value;
    this.itemsListChange.emit(this.itemsList);
  }

  /// без этого при каждом изменении значения происходит пересоздание dom (пересоздание div-input со сбросом фокуса)
  /// SoDD: https://stackoverflow.com/questions/39979831/angular-2-with-ngfor-is-it-possible-to-update-values-without-rebuilding-the-d
  trackByFn(index, item) {
    return index;
  }
}
