import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [BrowserAnimationsModule],
  exports: [
    BrowserAnimationsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatGridListModule,
    MatIconModule,
  ],
})
export class AppMaterialModule {}
