import { Component, Input, OnInit } from '@angular/core';
import { FieldConfig } from 'src/app/model/field-description';

@Component({
  selector: 'app-attribute-details',
  templateUrl: './attribute-details.component.html',
  styleUrls: ['./attribute-details.component.css'],
})
export class AttributeDetailsComponent implements OnInit {
  @Input()
  public field: FieldConfig;

  constructor() {}

  ngOnInit(): void {}
}
