import { CatalogResource } from '../model/catalog-resource';
import {
  CatalogService,
  GeoExtent,
  ServiceType,
} from '../model/catalog-service';
import { FieldConfig } from '../model/field-description';

export class ArcGisCatalog {
  title: string;
  baseurl: string;

  private rootElements: ArcGisCatalogNode[] = [];

  constructor(title: string, arcGisBaseUrl: string) {
    this.title = title;
    this.baseurl = arcGisBaseUrl;

    this.getRootEntries();
  }

  async getRootEntries(): Promise<ArcGisCatalogNode[]> {
    if (this.rootElements.length == 0) {
      this.rootElements = await this.GetNodesServices('');
    }

    return this.rootElements;
  }

  getChildEntries(node: ArcGisCatalogNode): Promise<ArcGisCatalogNode[]> {
    if (node == null) {
      return this.getRootEntries();
    }

    var childElements = null;

    switch (node.level) {
      case AGSNodeLevel.folder:
        childElements = this.GetNodesServices(node.folderName);
        break;
      case AGSNodeLevel.service:
        childElements = this.GetNodesLayers(node);
        break;
      case AGSNodeLevel.layer:
        childElements = this.GetNodesLayers(node);
        break;
      default:
        childElements = [];
        break;
    }

    return childElements;
  }

  async GetNodesServices(folderPath: string): Promise<ArcGisCatalogNode[]> {
    var query = await fetch(
      this.baseurl + '/rest/services/' + folderPath + '?f=json'
    );
    var elementList = await query.json();

    var ans: ArcGisCatalogNode[] = [];

    // Получить список папок внутри узла (смысл имеет только для корневого каталога)
    if (
      elementList &&
      elementList['folders'] &&
      elementList['folders'].length
    ) {
      for (var folderName of elementList['folders']) {
        var newElement = this.GetNodeElement(folderName);
        ans.push(newElement);
      }
    }

    // Получить список сервисов внутри папки
    if (
      elementList &&
      elementList['services'] &&
      elementList['services'].length
    ) {
      for (var serviceDesciption of elementList['services']) {
        var svcTitle = serviceDesciption['name'];
        var svcType = serviceDesciption['type'];

        var newElement = this.GetNodeElement(folderPath, svcType, svcTitle);
        newElement.resource = await this.GetResourceFor(
          svcTitle,
          newElement.GetURL()
        );
        newElement.resource.fields = await this.loadLayerInfo(newElement);
        ans.push(newElement);
      }
    }
    return ans;
  }

  async GetNodesLayers(
    nodeDescr: ArcGisCatalogNode
  ): Promise<ArcGisCatalogNode[]> {
    var ans: ArcGisCatalogNode[] = [];
    var qQuery = await fetch(
      this.baseurl + '/rest/services/' + nodeDescr.GetURL() + '?f=json'
    );
    var elementList = await qQuery.json();

    if (elementList && elementList['layers'] && elementList['layers'].length) {
      var parentLayer = nodeDescr.RootLayerID ?? -1;
      for (var layerDesciption of elementList['layers']) {
        if (layerDesciption['parentLayerId'] != parentLayer) {
          continue;
        }
        var layerID = layerDesciption['id'];
        var layerTitle = layerDesciption['name'];

        var newElement = this.GetNodeElement(
          nodeDescr.folderName,
          nodeDescr.serviceType,
          nodeDescr.serviceName,
          layerID,
          layerTitle
        );

        var layersIdList = this.GetLeafLayersIDs(
          elementList['layers'],
          newElement.RootLayerID
        );
        newElement.resource = await this.GetResourceFor(
          layerTitle,
          nodeDescr.GetURL(),
          layersIdList
        );
        newElement.resource.fields = await this.loadLayerInfo(newElement);
        newElement.hasChildren =
          layerDesciption['subLayerIds'] &&
          layerDesciption['subLayerIds'].length &&
          layerDesciption['subLayerIds'].length > 0
            ? true
            : false;

        ans.push(newElement);
      }
    }

    return ans;
  }

  async loadLayerInfo(node: ArcGisCatalogNode): Promise<FieldConfig[]> {
    let ans: FieldConfig[] = [];

    var vInfoUrl =
      this.baseurl + '/rest/services/' + node.GetURL() + '/layers?f=json';

    /// Слои, для которых вообще нужно загружать описания.
    var layersInNode: string[] = [];
    var layersToModule: { [resourceID: string]: string } = {};
    for (var svc of node.resource.service) {
      layersInNode = layersInNode.concat(svc.layers);
      for (var layerId in svc.layers) {
        layersToModule[layerId] = svc.title;
      }
    }

    var layerInfoArrayAjax = await fetch(vInfoUrl);
    let layerInfoArray = await layerInfoArrayAjax.json();

    for (let layerInfo of layerInfoArray.layers) {
      try {
        let layerID = layerInfo.id;
        /// не загружать поля для слоев, которые не будут отображаться.
        if (!layersInNode.includes(layerID)) {
          continue;
        }
        var moduleName = layersToModule[layerID];

        /// формируем список полей (для поиска)
        if (layerInfo.fields && Array.isArray(layerInfo.fields)) {
          /// иногда fields - не массив. ворнинги.
          for (let fieldInfo of layerInfo.fields) {
            let newFieldDesc = new FieldConfig();
            let fldName = fieldInfo.name;
            let fldAlias = fieldInfo.alias;
            newFieldDesc.module = moduleName;
            newFieldDesc.name = fldAlias;
            newFieldDesc.filterName = fldName;
            newFieldDesc.title = fldAlias;
            newFieldDesc.layer = layerID;

            if (fieldInfo.domain && fieldInfo.domain.codedValues) {
              newFieldDesc.possibleValues = fieldInfo.domain.codedValues;
            }

            ans.push(newFieldDesc);
          }
        }
      } catch (e) {
        console.log('Ошибка чтения информации о слое');
      }
    }

    return ans;
  }

  /// ArcGIS  в identify запросе почему-то для иерархических слоев (когда "1" дочерний для "0") объект 2 раза - и для "0" и для "1"
  /// потому выбираем только дочерние. чтобы с одной стороны можно было каждый независимо отключить. С другой не иметь дублирований в identify
  GetLeafLayersIDs(pLayersList: any, pRootLayerID: string = '-1'): string[] {
    var ans: string[] = [];
    for (var layer of pLayersList) {
      if (layer['parentLayerId'] == pRootLayerID) {
        var hasChildren =
          layer['subLayerIds'] &&
          layer['subLayerIds'].length &&
          layer['subLayerIds'].length > 0;
        /// Добавляются только листовые элементы. Т.к. остальные смысла не несут, но приводят к дублированию почему-то
        if (hasChildren) {
          var childIDS: string[] = this.GetLeafLayersIDs(
            pLayersList,
            layer['id']
          );
          ans = ans.concat(childIDS);
        } else {
          ans.push(layer['id']);
        }
      }
    }
    /// если ни одного дочернего элемента не нашлось вообще, значит этот элемент и сам листовой.
    if (ans.length == 0) {
      ans.push(pRootLayerID);
    }
    return ans;
  }

  GetNodeElement(
    pFolderPath: string,
    pServiceType: string = null,
    pServiceName: string = null,
    pLayerID: string = null,
    pLayerName: string = null
  ): ArcGisCatalogNode {
    var newElement = new ArcGisCatalogNode(
      pFolderPath,
      pServiceType,
      pServiceName,
      pLayerID,
      pLayerName
    );
    return newElement;
  }

  async GetResourceFor(
    pTitle: string,
    pServicePath: string,
    pLayersList: string[] = []
  ): Promise<CatalogResource> {
    var url = this.baseurl + '/rest/services/' + pServicePath + '?f=json';
    var queryAns = await fetch(url);
    var serviceDesc = await queryAns.json();
    if (!serviceDesc) {
      return null;
    }

    var newServiceConfig = new CatalogService();
    newServiceConfig.type =
      serviceDesc['singleFusedMapCache'] == true
        ? ServiceType.arcgistiled
        : ServiceType.arcgis;
    newServiceConfig.title = serviceDesc['mapName'];
    newServiceConfig.url = this.baseurl + '/rest/services/' + pServicePath;

    var jsonExtent = serviceDesc['fullExtent'];
    if (jsonExtent) {
      newServiceConfig.extent = new GeoExtent(
        jsonExtent['spatialReference']['wkid'],
        jsonExtent['xmin'],
        jsonExtent['xmax'],
        jsonExtent['ymin'],
        jsonExtent['ymax']
      );
    }

    newServiceConfig.layers = [];
    if (pLayersList.length > 0) {
      newServiceConfig.layers = pLayersList;
    } else if (serviceDesc['layers'] && Array.isArray(serviceDesc['layers'])) {
      newServiceConfig.layers = this.GetLeafLayersIDs(serviceDesc['layers']);
    }

    var newResource = new CatalogResource();

    newResource.title = pTitle;
    newResource.service.push(newServiceConfig);

    return newResource;
  }

  public async GetUniqValues(
    pLayerID: string,
    pFieldName: string,
    pUserInput: string
  ) {
    let url =
      this.baseurl +
      '/' +
      pLayerID +
      '/query?f=json' +
      '&outFields=' +
      pFieldName +
      '&returnGeometry=false' +
      '&returnIdsOnly=false' +
      '&returnCountOnly=false' +
      '&orderByFields=' +
      pFieldName +
      '&groupByFieldsForStatistics=' +
      pFieldName +
      '&outStatistics=%5B%7B%0D%0A+%22statisticType%22%3A+%22count%22%2C%0D%0A+%22onStatisticField%22%3A+%22' +
      pFieldName +
      '%22%2C+%22outStatisticFieldName%22%3A+%22Count%22%0D%0A%7D%5D' +
      '&returnDistinctValues=true' +
      '&where=' +
      pFieldName +
      "+like+'%" +
      pUserInput +
      "%'";

    let agsResponseAjax = await fetch(url);
    let agsResponse = await agsResponseAjax.json();

    let ans = [];
    if (agsResponse.features) {
      for (let ftr of agsResponse.features) {
        let val = ftr['attributes'][pFieldName];
        let cnt = ftr['attributes']['COUNTDISTINCT'];
        ans.push({
          name: val + '(' + cnt + ')',
          code: val,
        });
      }
    }

    return ans;
  }
}

enum AGSNodeLevel {
  folder,
  service,
  layer,
}

export class ArcGisCatalogNode {
  id: string = '123123';
  name: string = '123';

  children: ArcGisCatalogNode[] = null;
  hasChildren = true;
  resource: CatalogResource;

  GetURL() {
    return this.folderName + '/' + this.serviceName + '/' + this.serviceType;
  }

  GetID(): string {
    return this.folderName + '/' + this.serviceName + '/' + this.RootLayerID;
  }

  GetName(): string {
    switch (this.level) {
      case AGSNodeLevel.folder:
        return this.folderName;
      case AGSNodeLevel.service:
        return this.serviceName + '(' + this.serviceType + ')';
      case AGSNodeLevel.layer:
        return this.RootLayerName;
      default:
        return '-';
    }
  }

  public level: AGSNodeLevel;
  public folderName: string;
  public serviceName: string;
  public serviceType: string;
  public RootLayerID: string;
  public RootLayerName: string;

  constructor(
    folder: string,
    serviceType: string,
    service: string,
    layer: string,
    layerName: string
  ) {
    this.folderName = folder;
    this.serviceName =
      service && service.indexOf('/') != -1 ? service.split('/')[1] : service;
    this.serviceType = serviceType;
    this.RootLayerID = layer;
    this.RootLayerName = layerName;
    this.level = AGSNodeLevel.folder;
    if (this.serviceName !== null && this.serviceName != '')
      this.level = AGSNodeLevel.service;
    if (this.RootLayerID !== null) this.level = AGSNodeLevel.layer;

    this.id = this.GetID();
    this.name = this.GetName();
  }
}
