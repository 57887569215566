import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ITreeOptions,
  TreeComponent,
  TreeNode,
} from '@circlon/angular-tree-component';
import { Guid } from 'guid-typescript';
import { CatalogNode } from 'src/app/model/catalog-node';
import { ArcGisCatalog, ArcGisCatalogNode } from 'src/app/tools/arcgis-catalog';

@Component({
  selector: 'app-external-service-catalog',
  templateUrl: './external-service-catalog.component.html',
  styleUrls: ['./external-service-catalog.component.css'],
})
export class ExternalServiceCatalogComponent implements OnInit {
  @ViewChild(TreeComponent)
  private treeExtSvc: TreeComponent;

  @Output()
  public onSelectNode: EventEmitter<CatalogNode> =
    new EventEmitter<CatalogNode>();
  selectedNode: CatalogNode;

  @Output()
  public onStatusUpdate: EventEmitter<string> = new EventEmitter<string>();

  catalogType: string = '';
  catalogUrl: string = '';

  nodesList: ArcGisCatalogNode[];
  catalogClass: ArcGisCatalog = null;

  treeExtSvcOptionsResource: ITreeOptions = {
    allowDrag: (n) => false,
    allowDrop: (n) => false,
  };

  @Input()
  set allowDrag(allow: boolean) {
    this.treeExtSvcOptionsResource.allowDrag = (n) => allow;
  }

  constructor() {
    var component = this;
    this.treeExtSvcOptionsResource.getChildren = async function (
      node: TreeNode
    ): Promise<ArcGisCatalogNode[]> {
      console.log('load child for ', node);
      var ans = await component.catalogClass.getChildEntries(node.data);
      return ans;
    };
  }

  ngOnInit(): void {}

  async ReadCatalog() {
    this.catalogClass = new ArcGisCatalog('-', this.catalogUrl);
    this.nodesList = await this.catalogClass.getRootEntries();

    this.treeExtSvc.treeModel.update();
  }

  async SelectNode(node: ArcGisCatalogNode) {
    this.selectedNode = this.agsNode2catNode(node);
    this.onSelectNode.emit(this.selectedNode);
  }

  agsNode2catNode(node: ArcGisCatalogNode): CatalogNode {
    var newNode = new CatalogNode();
    newNode.name = node.GetName();
    newNode.id = Guid.create().toString();
    newNode.type = 'resource';
    newNode.resource = node.resource;
    return newNode;
  }
}
