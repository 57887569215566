<p>Настройка сервиса</p>

<table>
    <tr>
        <td>ID</td>
        <td>{{service.id}} </td>
    </tr>
    <tr>
        <td>Title</td>
        <td>
            <div-input [(value)]="service.title" edit="true"></div-input>
        </td>

    </tr>
    <tr>
      <td>Description</td>
      <td>
          <div-input [(value)]="service.description" edit="true"></div-input>
      </td>

  </tr>

    <tr>
        <td>Type</td>
        <td> <select [(ngModel)]="service.type">
                <option value="arcgis">ArcGis MapServer</option>
                <option value="arcgistiled">ArcGis MapServer Tiled (Кэшированный)</option>
                <option value="arcgisfeature">ArcGis FeatureServer</option>
                <option value="nextgiswebmap">NextGIS Веб-карта</option>
                <option value="nextgistiled">NextGIS Веб-карта (nextgistiled)</option>
                <option value="wms">WMS</option>
                <option value="wmts">WMTS</option>
                <option value="wfs">WFS</option>
                <option value="xyz">XYZ</option>
                <option value="rest">REST-сервис</option>
                <option value="url">Ссылка (URL)</option>
                <option value="geojson">GeoJSON</option>
                <option value="kml">KML</option>
                <option value="vector">Локальный векторный слой</option>
            </select> </td>
    </tr>
    <tr>
        <td>Url</td>
        <td>
            <div-input [(value)]="service.url" edit="true"></div-input>
        </td>
    </tr>

    <tr>
        <td>Слои (layers)</td>
        <td>
            <app-strings-list [(itemsList)]="service.layers"></app-strings-list>
        </td>
    </tr>

    <tr>
        <td>Слои для Query-запроса</td>
        <td>
            <app-strings-list [(itemsList)]="service.queryLayers"></app-strings-list>
        </td>
    </tr>

    <tr>
      <td>Слои, активные при запуске</td>
      <td>
          <app-strings-list [(itemsList)]="service.startLayers"></app-strings-list>
      </td>
    </tr>

    <tr>
      <td>Псевдонимы слоев</td>
      <td>
          <table border="1" *ngIf="service.layerAliases?.length">
              <tr>
                  <td></td>
                  <td>Имя</td>
                  <td>Псевдоним</td>
              </tr>

              <tr *ngFor="let alias of service.layerAliases; let index=index">
                  <td><button (click)="service.layerAliases.splice(index, 1)">X</button></td>
                  <td>
                      <div-input [(value)]="alias.name" edit="true"></div-input>
                  </td>
                  <td>
                    <div-input [(value)]="alias.alias" edit="true"></div-input>
                  </td>
              </tr>
              <tr>
                  <td colspan="2">
                      <button (click)="service.layerAliases.push({ name: '' , alias: '' })">Добавить псевдоним</button>
                  </td>
              </tr>
          </table>
          <button *ngIf="!service.layerAliases?.length" (click)="service.layerAliases=[{ name: '' , alias: ''}]">Создать псевдоним</button>
      </td>
    </tr>

    <tr>
        <td>Ограничение на количество записей при запросе</td>
        <td>
            <div-input [(value)]="service.maxFeatures" edit="true"></div-input>
        </td>
    </tr>

    <tr>
        <td>Прокси</td>
        <td>
            <div-input [(value)]="service.proxy" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Поле-идентификатор</td>
        <td>
            <div-input [(value)]="service.fieldID" edit="true"></div-input>
        </td>
    </tr>

    <!-- WMS -->
    <tr>
        <td>Version (wms,wfs)</td>
        <td>
            <div-input [(value)]="service.version" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>ResponseFormat</td>
        <td>
            <div-input [(value)]="service.responseFormat" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Style (wms)</td>
        <td>
            <div-input [(value)]="service.style" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>CustomParameters</td>
        <td>
            <app-key-value-list [(itemsList)]="service.customParameters"></app-key-value-list>
        </td>
    </tr>

    <tr>
      <td>Кодировать запрос при исопльзовании прокси-сервера</td>
      <td> <input type="checkbox" [(ngModel)]="service.encodeProxyRequest"> </td>
    </tr>

    <tr>
      <td>Разрешено скачивание данных</td>
      <td> <input type="checkbox" [(ngModel)]="service.downloadEnabled"> </td>
    </tr>

    <tr>
      <td>Предоставляет список слоев</td>
      <td> <input type="checkbox" [(ngModel)]="service.layersListEnabled"> </td>
    </tr>

    <tr>
        <td>Видимость по умолчанию</td>
        <td> <input type="checkbox" [(ngModel)]="service.visible"> </td>
    </tr>
    <tr>
        <td>Прозрачность по умолчанию</td>
        <td>
            <div-input [(value)]="service.opacity" edit="true"></div-input>
        </td>
    </tr>

    <tr>
        <td>Encoding</td>
        <td>
            <div-input [(value)]="service.encoding" edit="true"></div-input>
        </td>
    </tr>

    <tr>
      <td>Content</td>
      <td>
          <div-input [(value)]="service.content" edit="true"></div-input>
      </td>
    </tr>

    <tr>
        <td>Поддерживает</td>
        <td>
            <input type="checkbox" [(ngModel)]="service.supportClick"> supportClick <br>
            <input type="checkbox" [(ngModel)]="service.supportMap"> supportMap <br>
            <input type="checkbox" [(ngModel)]="service.supportQuery"> supportQuery <br>
        </td>
    </tr>
    <tr>
        <td>Разрешения</td>
        <td>
            <input type="checkbox" [(ngModel)]="service.edit"> Редактирование <br>
            <input type="checkbox" [(ngModel)]="service.editGeometry"> Редактирование геометрии <br>
        </td>
    </tr>

    <tr>
        <td>Extent</td>
        <td *ngIf="service.extent">
            SRS <input [(ngModel)]="service.extent.srs"><br>
            MaxY <input [(ngModel)]="service.extent.ymax" size=7><br>
            X <input [(ngModel)]="service.extent.xmin" size=7> -- <input [(ngModel)]="service.extent.xmax" size=7><br>
            MinY <input [(ngModel)]="service.extent.ymin" size=7><br>
        </td>
        <td *ngIf="!service.extent">
            <button (click)="AddExtent()">Add</button>
        </td>
    </tr>

    <tr>
      <td>Парсеры легенды</td>
      <td>
          <app-strings-list [(itemsList)]="service.legendParsers"></app-strings-list>
      </td>
    </tr>

    <tr>
      <td>Текст, добавляемый к заголовку легенды</td>
      <td>
          <table border="1" *ngIf="service.addTextToLegend?.length">
              <tr>
                  <td></td>
                  <td>Имя слоя</td>
                  <td>Префикс</td>
                  <td>Постфикс</td>
              </tr>

              <tr *ngFor=" let added of service.addTextToLegend; let index=index">
                  <td><button (click)="service.addTextToLegend.splice(index,1)">X</button></td>
                  <td>
                      <div-input [(value)]="added.name" edit="true"></div-input>
                  </td>
                  <td>
                      <div-input [(value)]="added.prefix" edit="true"></div-input>
                  </td>
                  <td>
                    <div-input [(value)]="added.postfix" edit="true"></div-input>
                </td>
              </tr>
              <tr>
                  <td colspan="2">
                      <button (click)=" service.addTextToLegend.push({ name: '' , prefix: '' , postfix: '' })">Добавить правило</button>
                  </td>
              </tr>
          </table>
          <button *ngIf="!service.addTextToLegend?.length" (click)="service.addTextToLegend= [{ name: '' , prefix: '' , postfix: '' }]">Создать правило</button>
      </td>
    </tr>

    <tr>
      <td>Текст, добавляемый к элементам легенды</td>
      <td>
          <table border="1" *ngIf="service.addTextToLegendElements?.length">
              <tr>
                  <td></td>
                  <td>Имя слоя</td>
                  <td>Префикс</td>
                  <td>Постфикс</td>
              </tr>

              <tr *ngFor=" let added of service.addTextToLegendElements; let index=index">
                  <td><button (click)="service.addTextToLegendElements.splice(index,1)">X</button></td>
                  <td>
                      <div-input [(value)]="added.name" edit="true"></div-input>
                  </td>
                  <td>
                      <div-input [(value)]="added.prefix" edit="true"></div-input>
                  </td>
                  <td>
                    <div-input [(value)]="added.postfix" edit="true"></div-input>
                </td>
              </tr>
              <tr>
                  <td colspan="2">
                      <button (click)=" service.addTextToLegendElements.push({ name: '' , prefix: '' , postfix: '' })">Добавить правило</button>
                  </td>
              </tr>
          </table>
          <button *ngIf="!service.addTextToLegendElements?.length" (click)="service.addTextToLegendElements= [{ name: '' , prefix: '' , postfix: '' }]">Создать правило</button>
      </td>
    </tr>

    <tr>
      <td>Спрятать легенду для слоев</td>
      <td>
          <app-strings-list [(itemsList)]="service.hideLegends"></app-strings-list>
      </td>
    </tr>

    <tr>
      <td>Спрятанные элементы легенды</td>
      <td>
          <table border="1" *ngIf="service.forbiddenLegendElements?.length">
              <tr>
                  <td></td>
                  <td>Имя слоя</td>
                  <td>Подпись к легенде</td>
              </tr>

              <tr *ngFor=" let element of service.forbiddenLegendElements; let index=index">
                  <td><button (click)="service.forbiddenLegendElements.splice(index,1)">X</button></td>
                  <td>
                      <div-input [(value)]="element.name" edit="true"></div-input>
                  </td>
                  <td>
                      <div-input [(value)]="element.label" edit="true"></div-input>
                  </td>
              </tr>
              <tr>
                  <td colspan="2">
                      <button (click)=" service.forbiddenLegendElements.push({ name: '' , label: '' })">Добавить правило</button>
                  </td>
              </tr>
          </table>
          <button *ngIf="!service.forbiddenLegendElements?.length" (click)="service.forbiddenLegendElements= [{ name: '' , label: '' }]">Создать правило</button>
      </td>
    </tr>

    <tr>
      <td>Слои, спрятанные при запуске</td>
      <td>
          <app-strings-list [(itemsList)]="service.hideLayers"></app-strings-list>
      </td>
    </tr>

    <tr>
        <td>CacheStatus</td>
        <td>
            <div-input [(value)]="service.cacheStatus" edit="true"></div-input>
        </td>
    </tr>

    <tr>
        <td>Атрибуты для списка найденных</td>
        <td>
            <app-strings-list [(itemsList)]="service.attributeName"></app-strings-list>
        </td>
    </tr>

    <tr>
        <td>Прятать легенду для слоев с id</td>
        <td>
            <app-strings-list [(itemsList)]="service.hideLegends"></app-strings-list>
        </td>
    </tr>

    <tr>
        <td>Шрифты (имя: путь к шрифту)</td>
        <td>
            <app-key-value-list [(itemsList)]="service.requiredFonts"></app-key-value-list>
        </td>
    </tr>

    <tr>
        <td>Автозамена</td>
        <td>
            <table border="1" *ngIf="service.htmlRewrite?.length">
                <tr>
                    <td></td>
                    <td>Найти текст</td>
                    <td>Заменить на</td>
                </tr>

                <tr *ngFor=" let rule of service.htmlRewrite; let index=index">
                    <td><button (click)="service.htmlRewrite.splice(index,1)">X</button></td>
                    <td>
                        <div-input [(value)]="rule.find" edit="true"></div-input>
                    </td>
                    <td>
                        <div-input [(value)]="rule.replaceWith" edit="true"></div-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button (click)=" service.htmlRewrite.push({ find: '' , replaceWith: '' })">Добавить
                            правило</button>
                    </td>
                </tr>
            </table>
            <button *ngIf="!service.htmlRewrite?.length"
                (click)="service.htmlRewrite= [{ find: '', replaceWith: '' }]">Создать правило</button>

        </td>
    </tr>
</table>
