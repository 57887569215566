Атрибут {{field.name}}

<table>
    <tr>
        <td colspan="2">Визуально в ВеГА</td>
    </tr>

    <tr>
        <td>Имя поля (в атр.таблице)</td>
        <td>
            <div-input [(value)]="field.title" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Url-префикс</td>
        <td>
            <div-input [(value)]="field.urlPrefix" edit="true"
                title="префикс, который подставляется при отображении поля, в общем случае это - первая часть URL, к которой нужно добавить ИД">
            </div-input>
        </td>
    </tr>
    <tr>
        <td>Описание поля</td>
        <td>
            <div-input [(value)]="field.description" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Примеры использования</td>
        <td>
            <div-input [(value)]="field.examples" edit="true"></div-input>
        </td>
    </tr>

    <tr>
        <td>Допустимые значения</td>
        <td>
            <app-strings-list [itemsList]="field.possibleValues"></app-strings-list>
        </td>
    </tr>

    <tr>
        <td colspan="2">Технические параметры</td>
    </tr>
    <tr>
        <td>Модуль (имя сервиса)</td>
        <td>
            <div-input [(value)]="field.module" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Атрибут (имя в ответе)</td>
        <td>
            <div-input [(value)]="field.name" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Имя слоя</td>
        <td>
            <div-input [(value)]="field.layer" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Тип данных, допустимый в поле ( string, number, date, disctionary) </td>
        <td>
            <div-input [(value)]="field.type" edit="true"></div-input>
        </td>
    </tr>
    <tr>
        <td>Name (для фильтра)</td>
        <td>
            <div-input [(value)]="field.filterName" edit="true"></div-input>
        </td>
    </tr>
    <tr>
      <td>Форматированное поле</td>
      <td>
          <input type="checkbox" [(ngModel)]="field.formatted">Форматированное поле<br>
      </td>
    </tr>
    <tr>
      <td>Поле не выводится</td>
      <td>
          <input type="checkbox" [(ngModel)]="field.hidden" />Поле не выводится<br>
      </td>
    </tr>

    <tr>
        <td>Группы</td>
        <td>
            <app-strings-list [itemsList]="field.groups"></app-strings-list>
        </td>
    </tr>
    <tr>
        <td>Группы (Чтение)</td>
        <td>
            <app-strings-list [itemsList]="field.allowReadGroups"></app-strings-list>
        </td>
    </tr>
    <tr>
        <td>Группы (Редактор)</td>
        <td>
            <app-strings-list [itemsList]="field.allowEditGroups"></app-strings-list>
        </td>
    </tr>

</table>
