import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';

@Component({
  selector: 'app-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.css'],
})
export class SitesListComponent implements OnInit {
  sitesList: Site[];

  tmpCode: string;

  constructor(
    private main: CatalogMainService,
    public config: WegaCatalogConfigService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.Reload();
  }

  ngOnInit(): void {
    this.Reload();
  }

  async SelectSite(site: Site) {
    this.router.navigateByUrl('/site/' + site.code);
  }

  async AddSite() {
    var newSite = await this.main.AddSite();
    newSite.code = this.tmpCode;
    newSite.name = this.tmpCode;
    await this.main.UpdateSite(newSite);

    this.sitesList.push(newSite);
  }

  async SaveSite(site: Site) {
    if (!site.code) {
      site.code = site.tmpCode;
    }

    var newSite = await this.main.UpdateSite(site);
    await this.Reload();

    this.snackBar.open('Конфигурация сайта сохранена', null, {
      duration: 5000,
    });

    this.SelectSiteByID(newSite.code);
  }

  async DeleteSite(site: Site) {
    var indx = this.sitesList.indexOf(site);

    await this.main.DeleteSite(site.code);

    this.sitesList.splice(indx, 1);
  }

  SelectSiteByID(id: string) {}

  async Reload() {
    this.sitesList = await this.main.GetSitesList();
  }

  FilterSites(sites: Site[]) {
    return (sites ?? [])
      .filter((x) => x)
      .sort((a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      });
  }
}
