<div class="Table">
    <div class="TableRow">
        <div class="TableCell">
            <div *ngIf="allowEdit">
                <table class="controlPanel">
                    <tr>
                        <td>
                            Site: {{site.code}}
                        </td>
                        <td>
                            <button (click)="SaveResourceList()" mat-icon-button title="Сохранить">
                                <mat-icon>save</mat-icon>
                            </button>
                        </td>
                        <td>
                            <button (click)="ModeCopyFromAnotherSite()" mat-icon-button
                            title="Копировать из другого сайта">
                                <mat-icon>input</mat-icon>
                            </button>
                            <button (click)="DuplicateNode()" mat-icon-button
                            title="Дублировать узел">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                            <button (click)="ModeGetFromExternalCatalog()" mat-icon-button
                                title="Получить из внешнего каталога">
                                <mat-icon>content_paste_search</mat-icon>
                            </button>
                            <button (click)="StartJsonEdit()" mat-icon-button title="Редактировать как Json">
                                <mat-icon>code</mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <span *ngIf="this.allowEdit">
                    <button (click)="AddRootNode()">Add</button>
                    <button (click)="DeleteSelectedReourceNode()">X</button>
                </span>
                <div>
                    <tree-root #treeResourceListOfSite [nodes]="wcNodes.nodes" [options]="treeOptions"
                        (activate)="SelectNode($event.node.data)">
                    </tree-root>
                </div>
            </div>
        </div>


        <div class="TableCell" *ngIf="wcNodes.selectedNode && importMode=='none'">
            <app-resource [site]="site" [node]="wcNodes.selectedNode">
            </app-resource>
        </div>

        <div class="TableCell" *ngIf="importMode=='anotherSite' ">
            <button *ngIf="selectedNodeForCopy" (click)="CopyResourceToTree()"> Скопировать
                {{selectedNodeForCopy.name}} </button>
            <br>
            <app-select-resource (onSelectNode)="SelectNodeForCopy($event)" [allowDrag]="'true'">
            </app-select-resource>
        </div>

        <div class="TableCell" *ngIf="importMode=='externalCatalog' ">
            <button *ngIf="selectedNodeForCopy" (click)="CopyResourceToTree()"> Каталог
                {{selectedNodeForCopy.name}} </button>
            <br>
            <app-external-service-catalog (onSelectNode)="SelectNodeForCopy($event)" [allowDrag]="'true'">
            </app-external-service-catalog>
        </div>

        <div class="TableCell" *ngIf="importMode=='Json'">
            <textarea rows="20" cols="100" [(ngModel)]="resourceJson"></textarea>
            <hr>
            <button (click)="CommitJsonEdit()">Применить</button>
            <button (click)="RevertJsonEdit()">Отмена</button>
            <span>Перед сохранением данных нужно нажать "Применить"</span>
        </div>
    </div>
</div>
