<h1>Параметры (ручное редактирование)</h1>
<span *ngIf="allowEdit">
  <button (click)="Save()">Сохранить</button>
</span>

<span *ngIf="!allowEdit">
  Редактирование запрещено
</span>

<br>
<textarea rows="20" cols="100" [(ngModel)]="params"></textarea>
