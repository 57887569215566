Выбрать из внешнего каталога

<table>
    <tr>
        <td>Тип каталога</td>
        <td>
            <select [(ngModel)]="catalogType">
                <option value="none">-</option>
                <option value="arcgis">ArcGIS</option>
            </select>
        </td>
    </tr>
    <tr>
        <td>URL</td>
        <td>
            <input [(ngModel)]="catalogUrl" list="urls">
        </td>
    </tr>
    <tr>
        <td></td>
        <td>
            <button (click)="ReadCatalog()">Прочитать каталог</button>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <tree-root #treeExtSvc [options]="treeExtSvcOptionsResource" [nodes]="nodesList"
                (activate)="SelectNode($event.node.data)">
            </tree-root>
        </td>
    </tr>
</table>


<datalist id="urls">
    <option>https://agssrv1.vsegei.ru/arcgis/</option>
    <option>https://gissrv103.vsegei.ru/arcgis/</option>
    <option>https://gissrv102.vsegei.ru/arcgis/</option>
    <option>https://gissrv101.vsegei.ru/arcgis/</option>
</datalist>
