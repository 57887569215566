import { Component } from '@angular/core';
import { WegaCatalogConfigService } from './model/wc-config.service';
import { WegaCatalogUser } from './model/wc-user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'WegaCatalogEditor';
  user: WegaCatalogUser;

  constructor(private config: WegaCatalogConfigService) {
    this.user = config.user;
  }

  SetBaseUrl(baseUrl) {
    this.config.SetBaseUrl(baseUrl);
  }
}
