<p>Список атрибутов</p>

<button (click)="AddField()">Добавить</button>
<hr>

<ul>
    <li *ngFor="let fld of attributes; index as i" [class.selected]="_selectedField==fld">
        <button (click)="DeleteField(i)"> x </button>
        <span (click)="SelectField(i)">
            [ {{fld.name}} ]
        </span>

    </li>
</ul>

<hr>
