Выбрать ресурс для копирования
<table>
    <tr>
        <td>
            <div>
                <tree-root [nodes]="sitesList" (activate)="SelectSite($event.node.data)">
                </tree-root>
            </div>

        </td>
        <td>
            <div>
                <tree-root [options]="treeOptionsResource" [nodes]="resourcesList" (activate)="SelectNode($event.node.data)">
                </tree-root>
            </div>
        </td>



    </tr>
</table>
