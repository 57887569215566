<span *ngIf="!user.isAuth">
    <a href="/login">login</a>
</span>

<span *ngIf="user.isAuth">
    Пользователь: {{user.name}} <a href="/logout">logout</a>
    <button (click)="SetBaseUrl('/api/')">(File)</button>
    <button (click)="SetBaseUrl('/api2/')">(DB)</button>
</span>

<span *ngIf="user.isAdmin">
    <a href="#/sites">
        <button>Sites</button>
    </a>
    <a href="#/config">
        <button>Config</button>
    </a>
</span>

<hr>

<div class="container">
    <router-outlet *ngIf="user.isAuth"></router-outlet>
</div>
