import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { noop } from 'rxjs';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputResetableComponent),
  multi: true,
};

@Component({
  selector: 'inputx',
  templateUrl: './input-resetable.component.html',
  styleUrls: ['./input-resetable.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputResetableComponent implements OnInit, ControlValueAccessor {
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() {}

  writeValue(v: any): void {
    if (v !== this.item) {
      this.item = v;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  @Input()
  set value(v: any) {
    if (v !== this.item) {
      if ('false' === v) v = false;
      this.item = v;
      this.valueChange.emit(this.item);
      this.onChangeCallback(this.item);
      console.log('change');
    }
  }

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  get value(): any {
    return this.item;
  }

  item: any;

  @Input()
  content: string;

  @Input()
  type: string = 'text';

  // @Input()
  // class: any;

  // @Input()
  // paramName: string;

  ngOnInit(): void {}

  public ToggleValue() {
    this.value = !this.value;
  }

  SetParam() {
    this.item = '-';
    if (this.type == 'checkbox') {
      this.item = true;
    }
    this.onChangeCallback(this.item);
  }
  ResetParam() {
    delete this.item;
    this.onChangeCallback(this.item);
  }
}
