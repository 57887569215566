import { Injectable } from '@angular/core';
import { WegaCatalogUser } from './wc-user';

@Injectable({
  providedIn: 'root',
})
export class WegaCatalogConfigService {
  baseUrl = '/api/';
  userUrl = '/api/user';

  public localConfig: any = {};

  public user: WegaCatalogUser = new WegaCatalogUser({});

  public allowEditSite: boolean = true;
  public allowDeleteSite: boolean = true;
  public allowAddSite: boolean = true;
  public adminUsers: string[];

  GetUrlConfig(): string {
    return this.baseUrl + 'config/';
  }

  async GetUrlSitesList(): Promise<string> {
    return this.baseUrl + 'sites/';
  }

  async GetUrlSite(site: string): Promise<string> {
    return this.baseUrl + 'site/' + site;
  }

  async GetUrlInit(site: string): Promise<string> {
    return this.baseUrl + 'site/' + site + '/init';
  }

  async GetUrlResourceList(site: string): Promise<string> {
    return this.baseUrl + 'site/' + site + '/resources';
  }

  async GetUrlResource(site: string, resource: string): Promise<string> {
    return this.baseUrl + 'site/' + site + '/resource/' + resource;
  }

  constructor() {
    this.LoadLocalConfig();
  }

  async LoadConfig(pForceRelaod: boolean = false): Promise<WegaCatalogConfigService> {
    /// Load Default Config
    try {
      var configMainJsonAjax = await fetch(this.GetUrlConfig());
      var configMainJson = await configMainJsonAjax.json();

      /// Получить данные текущего пользователя
      var userAjax = await fetch(this.userUrl);
      this.user = await userAjax.json();

      Object.assign(this, configMainJson);
      Object.assign(this, this.localConfig);
    } catch (e) {
      return null;
    }
    //this.onChange.next([]);
    return this;
  }

  public SetBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
    this.localConfig['baseUrl'] = baseUrl;
    this.SaveLocalConfig();
  }

  public LoadLocalConfig() {
    var localConfigJson = localStorage.getItem('localConfig');
    try {
      this.localConfig = JSON.parse(localConfigJson) ?? {};
      Object.assign(this, this.localConfig);
    } catch {
      console.error('Unable to load localConfig');
    }
  }

  public SaveLocalConfig() {
    localStorage.setItem('localConfig', JSON.stringify(this.localConfig));
  }
}
