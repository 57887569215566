import { Guid } from 'guid-typescript';
import { CatalogService, GeoExtent } from './catalog-service';
import { FieldConfig } from './field-description';

export class CatalogResource {
  /** Идентификатор ресурса. Если не задано, то генерируется автоматически при инициализации ресурса. */
  public id: string;

  /** Название (отображается пользователю). */
  public title: string;

  /** Полное название (может отсутствовать). */
  public longTitle: string;

  /** Источник ресурса (карты). */
  public origin: string;

  /** Актуальность ресурса. */
  public relevance: string;

  /** Коллекция сервисов, составляющих ресурс. По умолчанию = []. */
  public servicesList: CatalogService[] = [];

  /** Описание ресурса (отображается для пользователя). */
  public description: string;

  /** Список полей, которых нужно выводить в виде форматированного текста. По умолчанию = []. */
  public formattedFields: string[] = [];

  /** Выполнять сортировку атрибутивных полей по названию.  По умолчанию = false. */
  public sortFields: boolean = false;

  /** Экстент карты ресурса. */
  public extent: GeoExtent;

  /** Атрибуты для вывода названия в окне выделенных features. По умолчанию = []. */
  public featureDisplayAttrs: string[] = [];

  /** Разрешается скачивать выбранные объекты (shapefile). По умолчанию = true. */
  public allowDownloadSelected = true;

  /// ресурс всегда должен выводиться поверх прочих карт
  public pinned = false;

  /** При добавлении ресурс выводится включенным. По умолчанию = true. */
  public visibility = true;

  /** Ресурс не будет показан в каталоге. По умолчанию = false. */
  public hidden = false;

  /** Явное указание способности ресурса реализовать запрос-фильтр. По умолчанию = false. */
  public filterEnabled = false;

  /** Список всех слоев ресурса (создается динамически). По умолчанию = []. */
  availableLayers: Array<{ name: string; title: string }> = [];

  /** Ресурс отключен. По умолчанию = false. */
  public disabled = false;

  /** Разрешены ли функции редактирования данных для данного ресурса. По умолчанию = false.
   * В перспективе этот параметр должен определяться автоматически, на основе анализа составляющих сервисов.
   */
  public editAllowed = false;

  public fields: FieldConfig[] = [];
  public extendFields: FieldConfig[] = [];

  /** Поля, которые не нужно выводить при запросе. По умолчанию = []. */
  public forbiddenFields: string[] = [];

  /** Нужно ли прятать пустые поля при выводе атрибутивных данных. Имеет приоритет над глобальным параметром HideEmptyAttributeValues. По умолчанию = undefined. */
  public hideEmptyFields: boolean | undefined = undefined;

  /** Нужно ли прятать легенду для тех слоев, в которых вообще отсутствуют элементы легенды. По умолчанию = false. */
  public hideEmptyLegends: boolean = false;

  /** Использовать для названий слоев только имена конечных слоев (без титулов групповых слоев). По умолчанию = false. */
  public onlyLastTitles: boolean = false;

  /** Если включен, то подбор необходимых слоев для панели слоев выполняется черехз indexOf (например, если layers=['Geol'], то в отбор попадут 'Geol', 'Geol_layer', 'Geol1'). По умолчанию = false. */
  public fuzzyLayersGrouping: boolean = false;

  /** Массив файловых легенд слоев. Используется для подмены динамической легенды слоя списком файлов.
   * Чтобы добавить легенду в дополнение к уже существующей легенде, а не вместо нее, можно указать "+{имя слоя}" в качестве name.
   * По умолчанию = []. */
  public fileLegends: Array<{ name: string; title: string; legends: string[] }>;

  /** Массив файловЫх легенда карты, которые будут отображаться перед легендами слоев. По умолчанию = []. */
  public mapFileLegends: Array<{ name: string; legends: string[] }> = [];

  /** Ресурс является контейнером - при его выборе нужно только включить/выключить дочерние элементы. По умолчанию = false. */
  public switchChildrenOnly: boolean = false;

  public service: CatalogService[] = [];

  constructor() {}

  CopyFrom(sourceResource: CatalogResource) {
    Object.assign(this, sourceResource);
    this.service = [];

    for (var sourceService of sourceResource.service) {
      let newService = new CatalogService(sourceService);
      this.service.push(newService);
    }
  }
}
