import { Guid } from 'guid-typescript';

export type ShapeEncoding = 'UTF8' | 'Win1251' | 'default';
export type WegaPluginCondition = 'service-start' | 'control-click';
export enum ServiceType {
  wms = 'wms',
  wmts = 'wmts',
  wfs = 'wfs',
  xyz = 'xyz',
  arcgis = 'arcgis',
  arcgistiled = 'arcgistiled',
  arcgisfeature = 'arcgisfeature',
  rest = 'rest',
  url = 'url',
  vector = 'vector',
  nextgiswebmap = 'nextgiswebmap',
  nextgistiled = 'nextgistiled',
}

export class GeoExtent {
  public srs: string;
  public xmin: number;
  public ymin: number;
  public xmax: number;
  public ymax: number;

  constructor(
    srs = '4326',
    minx = null,
    miny = null,
    maxx = null,
    maxy = null
  ) {
    this.srs = srs;
    this.xmin = +minx;
    this.ymin = +miny;
    this.xmax = +maxx;
    this.ymax = +maxy;
  }
}

export class CatalogService {
  /** Идентификатор сервиса. */
  id: string;

  /** Название сервиса (которое видит пользователь). */
  title: string;

  /** Тип сервиса (wms, wfs, arcgis и т.п.) */
  type: ServiceType;

  /** URL (точка входа) сервиса. */
  url: string;

  /** URL прокси, который должен использоваться при загрузке сервиса. */
  proxy: string;

  /** Нужно ли кодировать URL при использовании прокси-сервера. По умолчанию = false. */
  encodeProxyRequest = false;

  /** Список имен слоев, которые нужно прочитать из сервиса (если он пуст, то включаются все слои). */
  layers: string[];

  /** Список слоев, которые должны быть активными при запуске карты. По умолчанию = null. */
  startLayers: string[];

  /** Список имен слоев, к которым делать getQueryInfo  (если он пуст, то используется список из layers). */
  queryLayers: string[];

  /** Список псевдонимов слоев. По умолчанию = []. */
  layerAliases: Array<{ name: string; alias: string }> = [];

  /** Максимальное количество объектов, получаемое за один запрос к ГИС-серверу. По умолчанию = 1000. */
  maxFeatures = 1000;

  /** Разрешено редактирование данных (атрибутивных) слоя. По умолчанию = false. */
  edit = false;

  /** Разрешено редактирование геометрии слоя. По умолчанию = false. */
  editGeometry = false;

  /** Имя поля, в котором содержится уникальный идентификатор объекта. */
  fieldID: string;

  /** Формат ответа (например, ожидаемый при WMS-запросе). По умолчанию = 'application/vnd.ogc.gml' */
  responseFormat = 'application/vnd.ogc.gml';

  /** Видимость сервиса. По умолчанию = true. */
  visible = true;

  /** Прозрачность карты сервиса. По умолчанию = 1. */
  opacity = 1;

  /** Данные сервиса (например GeoJSON), требуются для создания сервиса с данными. */
  content: any;

  /** Версия используемого протокола. Для WMS: '1.0.0', '1.1.0', '1.1.1', '1.3.0'. Для WFS: '1.0.0'. По умолчанию = null. */
  version: string;

  /** Поддерживает ли сервис GetFeatureInfo запросы (для WMS). По умолчанию = true. */
  supportClick: boolean = true;

  /** Поддерживает ли сервис рисование карты. По умолчанию = true. */
  supportMap: boolean = true;

  /** Поддерживает ли сервис запросы объектов по атрибутам. По умолчанию = true. */
  supportQuery: boolean = true;

  /** Экстент сервиса. */
  extent: GeoExtent;

  /** Список парсеров легенды. Стандартные парсеры: 'ag-font'. По умолчанию = []. */
  legendParsers: Array<'ag-font'> = [];

  /** Текст, добавляемый к заголовку легенды. По умолчанию = []. */
  addTextToLegend: Array<{ name: string; prefix: string; postfix: string }> = [];

  /** Текст, добавляемый к описаниями элементов легенды. По умолчанию = []. */
  addTextToLegendElements: Array<{ name: string; prefix: string; postfix: string }> = [];

  /** Элементы легенды слоев, которые не нужно отображать. По умолчанию = []. */
  forbiddenLegendElements: Array<{ name: string; label: string }> = [];

  /** Список слоев сервиса, для которых не надо выводить легенду. Чтобы спрятать все слои, можно указать ["*"]. По умолчанию = []. */
  hideLegends: Array<string> = [];

  /** Список слоев сервиса, которые выводятся спрятанными. По умолчанию = []. */
  /** Пока полноценно реализовано только для ArcGIS-слоев */
  hideLayers: Array<string> = [];

  /** Правила трансформации текста ответа (для WMS GetFeatureInfo). По умолчанию = []. */
  htmlRewrite: Array<{ find: string; replaceWith: string }> = [];

  /** Список шрифтов, которые необходимо внедрить на страницу для обеспечения корректного отображения сервиса. По умолчанию = []. */
  requiredFonts: Array<{ fontName: string; fontPath: string }> = [];

  /** Наличие кэшированного зеркала для сервиса. По умолчанию = '-1'. */
  public cacheStatus = '-1';

  /** Дополнительные сведения о сервисе (в свободной форме). По умолчанию = null. */
  public description: string = null;

  /** Описание нестандартного стиля. По умолчанию = null. */
  public style: string = null;

  /** Разрешено ли скачивания данных сервиса. По умолчанию = false. */
  public downloadEnabled = false;

  /** Способен ли сервис предоставлять список слоев. По умолчанию = true. */
  public layersListEnabled = true;

  /** Кодировка данных сервиса (используется для формирования CPG-файла при скачивании). */
  public encoding: ShapeEncoding;

  /** Имя поля, используемое в качестве имени объекта в при отборе данных. По умолчанию = [].  */
  public attributeName: string[] = [];

  /** Дополнительные параметры. По умолчанию = { "x": "y" }.  */
  public customParameters: { [key: string]: string } = { x: 'y' };

  /** Неструктурированные сведения о плагинах-обработчиках ресурса (их парсинг реализуется поставщиками конкретных плагинов). */
  public plugins: {
    [pluginId: string]: { call: Array<WegaPluginCondition> } & {
      [key: string]: string;
    };
  } = {};

  constructor(sourceService?: CatalogService) {
    if (sourceService) {
      Object.assign(this, sourceService);
    }

    this.id = Guid.create().toString();
    if (!this.customParameters) {
      this.customParameters = {};
    }
  }
}
