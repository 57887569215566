import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';
import { WegaCatalogNodeCollection } from 'src/app/model/wc-nodes';
import { Guid } from 'guid-typescript';
import { CatalogResource } from 'src/app/model/catalog-resource';

@Component({
  selector: 'app-site-import-json',
  templateUrl: './site-import-json.component.html',
  styleUrls: ['./site-import-json.component.css'],
})
export class SiteResourcesJSONComponent implements OnInit {
  _site: Site;
  allowEdit: boolean;

  @Input()
  set site(val: Site) {
    this._site = val;
  }

  get site(): Site {
    return this._site;
  }

  constructor(
  private main: CatalogMainService,
  private config: WegaCatalogConfigService,
  private wcNodes: WegaCatalogNodeCollection,
  private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.allowEdit = this.config.allowEditSite && this.site.allowEdit;
  }

  resources: any;
  async Import() {
    try {
      const data: any[] = JSON.parse(this.resources);

      const newNode = {} as any;
      newNode.type = "folder";
      newNode.name = "Импортированный каталог";
      newNode.children = data.map(d => SiteResourcesJSONComponent.configToNode(d, this.main));
      newNode.id =  Guid.create().toString();
      newNode.moduleType = null;
      newNode.moduleUrl = null;

      this.wcNodes.nodes.push(newNode);
      this.wcNodes.updateTree();

      this.snackBar.open('Список ресурсов сохранен', null, {
        duration: 5000,
      });
    } catch (e) {
      this.snackBar.open('Ошибка импорта, см. консоль', null, {
        duration: 3000,
      });

      console.log(e);
    }
  }

  static configToNode(node: any, main: CatalogMainService) {
    const newNode = <any> {};
    newNode.id = node.id ||  Guid.create().toString();
    newNode.name = node.title;

    if (node.module) { // модуль
      newNode.type = "module";

      newNode.moduleUrl = node.url;
      newNode.moduleType = node.module;
    } else if (!!node.service) { // ресурс
      newNode.type = "resource";
      Object.assign(newNode, node);
      main.CacheResource(newNode.id, <CatalogResource>newNode);
      if (node.children) {
        newNode.children = node.children.map(d => SiteResourcesJSONComponent.configToNode(d, main));
      }

      newNode.resource = void 0;
    } else { // папка
      newNode.type = "folder";

      if (node.children) {
        newNode.children = node.children.map(d => SiteResourcesJSONComponent.configToNode(d, main));
      }
    }

    return newNode;
  }
}
