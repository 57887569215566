import { Guid } from 'guid-typescript';
import { CatalogResource } from './catalog-resource';
import { CatalogService } from './catalog-service';

export class CatalogNode {
  public id: string;
  public name: string;
  public children: CatalogNode[];

  public hasChildren: boolean = true;

  /// Тип узла - module/Folder/Map
  public type: string;
  public moduleType: string;
  public moduleUrl: string;

  public resource: CatalogResource = new CatalogResource();

  get expandable(): boolean {
    return this.children != null && this.children.length > 0;
  }
  get title(): string {
    return this.name;
  }

  constructor() {
    this.id = Guid.create().toString();
  }

  public static ValidateFix(node: CatalogNode) {
    if (!node.resource) {
      node.resource = new CatalogResource();
    }

    if (!node.resource.service || !Array.isArray(node.resource.service)) {
      node.resource.service = [];
    }

    if (!node.resource.fields) {
      node.resource.fields = [];
    }
  }

  CopyFrom(sourceNode: CatalogNode) {
    this.name = sourceNode.name;
    this.moduleType = sourceNode.moduleType;
    this.moduleUrl = sourceNode.moduleUrl;
    this.type = sourceNode.type;

    this.resource.CopyFrom(sourceNode.resource);
  }
}
