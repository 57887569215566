<h1>Импорт ресурсов</h1>

<span *ngIf="!allowEdit">
  Редактирование запрещено
</span>

<br>
<textarea rows="20" cols="100" [(ngModel)]="resources"></textarea>

<span *ngIf="allowEdit">
  <button (click)="Import()">Добавить в дерево ресурсов</button>
</span>
