import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SitesListComponent } from './ui/sites-list/sites-list.component';
import { SiteComponent } from './ui/site/site.component';
import { ResourceComponent } from './ui/resource/resource.component';

import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material.module';
import { SiteMetadataComponent } from './ui/site-metadata/site-metadata.component';
import { SiteParamsComponent } from './ui/site-params/site-params.component';
import { SiteResourcesComponent } from './ui/site-resources/site-resources.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { ResourceMetadataComponent } from './ui/resource-metadata/resource-metadata.component';
import { ServiceListComponent } from './ui/service-list/service-list.component';
import { ServiceDetailsComponent } from './ui/service-details/service-details.component';
import { AttributesListComponent } from './ui/attributes-list/attributes-list.component';
import { AttributeDetailsComponent } from './ui/attribute-details/attribute-details.component';
import { StringsListComponent } from './ui-control/strings-list/strings-list.component';
import { SelectResourceComponent } from './ui-control/select-resource/select-resource.component';
import { InputResetableComponent } from './ui-control/input-resetable/input-resetable.component';
import { CatalogConfigurationComponent } from './ui/catalog-configuration/catalog-configuration.component';
import { WegaCatalogConfigService } from './model/wc-config.service';
import { ExternalServiceCatalogComponent } from './ui/external-service-catalog/external-service-catalog.component';
import { SiteParamsJSONComponent } from './ui/site-params-json/site-params-json.component';
import { DivInputComponent } from './ui-control/div-input/div-input.component';
import { KeyValueListComponent } from './ui-control/key-value-list/key-value-list.component';
import { SiteResourcesJSONComponent } from './ui/site-import-json/site-import-json.component';

export function initApp(config: WegaCatalogConfigService) {
  return () => {
    var configPromise = config.LoadConfig();
    return configPromise;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SitesListComponent,
    SiteComponent,
    SiteMetadataComponent,
    SiteParamsComponent,
    SiteResourcesComponent,
    SiteParamsJSONComponent,
    SiteResourcesJSONComponent,
    ResourceMetadataComponent,
    ServiceListComponent,
    ServiceDetailsComponent,
    ResourceComponent,
    AttributesListComponent,
    AttributeDetailsComponent,
    StringsListComponent,
    KeyValueListComponent,
    SelectResourceComponent,
    InputResetableComponent,
    CatalogConfigurationComponent,
    ExternalServiceCatalogComponent,
    DivInputComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TreeModule,
    AppMaterialModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [WegaCatalogConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
