import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { Site } from 'src/app/model/site';

@Component({
  selector: 'app-select-resource',
  templateUrl: './select-resource.component.html',
  styleUrls: ['./select-resource.component.css'],
})
export class SelectResourceComponent implements OnInit {
  treeOptionsResource = {
    allowDrag: false,
    allowDrop: false,
  };

  sitesList: Site[];
  selectedSite: Site;
  resourcesList: CatalogNode[];

  @Input()
  set allowDrag(allow: boolean) {
    this.treeOptionsResource.allowDrag = allow;
  }

  @Output() public onSelectNode: EventEmitter<CatalogNode> = new EventEmitter<CatalogNode>();
  selectedNode: CatalogNode;

  constructor(public main: CatalogMainService) {
    this.Load();
  }

  async Load() {
    this.sitesList = await this.main.GetSitesList();
  }

  async SelectSite(site: Site) {
    this.selectedSite = site;
    this.resourcesList = await this.main.GetResourceList(this.selectedSite);
  }

  async SelectNode(node: CatalogNode) {
    this.selectedNode = node;
    this.onSelectNode.emit(node);
  }

  ngOnInit(): void {}
}
