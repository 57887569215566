<div class="Table">
  <div class="TableBody">
    <div class="TableHead">
      <div class="TableCell columnMaps">Карта</div>
      <div class="TableCell columnLayers">Слой</div>
      <div class="TableCell columnFieldsList">Список полей слоя</div>
    </div>
    <div class="TableRow">
      <div class="TableCell columnMap">
        <div>
          <app-resource-metadata [node]="node"></app-resource-metadata>
        </div>
      </div>

      <div class="TableCell columnLayers">
        <div [hidden]="this.showLayerDescription">
          <app-service-list
            [serviceList]="node.resource?.service"
            (selectedService)="onSelectService($event)"
          >
          </app-service-list>
        </div>
        <div *ngIf="this.showLayerDescription">
          <button (click)="this.showLayerDescription = false">
            <span class="material-icons md-18" title="Выбрать другую карту"
              >arrow_back</span
            >
          </button>
          <app-service-details
            [service]="selectedService"
          ></app-service-details>
        </div>
      </div>

      <div class="TableCell columnFieldsList">
        <div [hidden]="this.showFieldDescription">
          Обычные поля:
          <app-attributes-list
            [attributes]="node.resource.fields"
            (selectedField)="onSelectField($event)"
          >
          </app-attributes-list>
        </div>
        <div [hidden]="this.showFieldDescription">
          Добавочные поля:
          <app-attributes-list
            [attributes]="node.resource.extendFields"
            (selectedField)="onSelectField($event)"
          >
          </app-attributes-list>
        </div>
        <div *ngIf="this.showFieldDescription">
          <button (click)="this.showFieldDescription = false">
            <span class="material-icons md-18" title="Выбрать другую карту"
              >arrow_back</span
            >
          </button>
          <app-attribute-details
            [field]="selectedAttribute"
          ></app-attribute-details>
        </div>
      </div>
    </div>
  </div>
</div>
