import { Component, Input, OnInit } from '@angular/core';
import { CatalogNode } from 'src/app/model/catalog-node';
import { GeoExtent } from 'src/app/model/catalog-service';

@Component({
  selector: 'app-resource-metadata',
  templateUrl: './resource-metadata.component.html',
  styleUrls: ['./resource-metadata.component.css'],
})
export class ResourceMetadataComponent implements OnInit {
  @Input() public node: CatalogNode;

  constructor() {}

  ngOnInit(): void {}

  AddExtent() {
    this.node.resource.extent = new GeoExtent();
  }
}
