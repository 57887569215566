<a href="/#/sites">К списку сайтов</a>

<p>
    Редактор сайта {{siteCode}}
    <a href="/api2/site/{{siteCode}}/todb" titlw="migrate to db">todb</a>
</p>
<div>
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Метаданные">
          <app-site-metadata *ngIf="site" [site]="site"></app-site-metadata>
        </mat-tab>

        <mat-tab label="Параметры">
          <app-site-params *ngIf="site" [site]="site"></app-site-params>
        </mat-tab>

        <mat-tab label="Параметры (JSON)">
          <app-site-params-json *ngIf="site" [site]="site"></app-site-params-json>
        </mat-tab>

        <mat-tab label="Ресурсы">
          <app-site-resources *ngIf="site" [site]="site"></app-site-resources>
        </mat-tab>

        <mat-tab label="Импорт (JSON)">
          <app-site-import-json *ngIf="site" [site]="site"></app-site-import-json>
        </mat-tab>
    </mat-tab-group>
</div>
