import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogConfigurationComponent } from './ui/catalog-configuration/catalog-configuration.component';
import { ResourceComponent } from './ui/resource/resource.component';
import { SiteComponent } from './ui/site/site.component';
import { SitesListComponent } from './ui/sites-list/sites-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'sites', pathMatch: 'full' },
  { path: 'sites', component: SitesListComponent },
  { path: 'list', component: SitesListComponent },
  { path: 'site/:site', component: SiteComponent },
  { path: 'site/:site/resource/:resource', component: ResourceComponent },
  { path: 'config', component: CatalogConfigurationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
