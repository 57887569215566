import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FieldConfig } from 'src/app/model/field-description';

@Component({
  selector: 'app-attributes-list',
  templateUrl: './attributes-list.component.html',
  styleUrls: ['./attributes-list.component.css'],
})
export class AttributesListComponent implements OnInit {
  @Input() attributes: FieldConfig[];
  _selectedField: FieldConfig;

  @Output() selectedField = new EventEmitter<FieldConfig>();

  constructor() {}

  ngOnInit(): void {}

  AddField() {
    var newField = new FieldConfig();
    newField.name = '-------';

    this.attributes.push(newField);
    this._selectedField = newField;
    this.selectedField.emit(this._selectedField);
  }

  DeleteField(indx: number) {
    this.attributes.splice(indx, 1);
  }

  SelectField(indx: number) {
    this._selectedField = this.attributes[indx];
    this.selectedField.emit(this._selectedField);
  }
}
