import { Injectable } from "@angular/core";
import { CatalogNode } from "./catalog-node";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WegaCatalogNodeCollection {
  public nodes: CatalogNode[] = [];
  public selectedNode: CatalogNode;

  public $$treeUpdate = new BehaviorSubject(false);

  constructor() {

  }

  updateTree() {
    this.$$treeUpdate.next(!this.$$treeUpdate.getValue());
  }
}
