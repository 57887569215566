export class FieldConfig {
  /** Имя сервиса в котором присутствует это поле (для многосервисных ресурсов). */
  public module: string;

  /** Имя поля в сервисе и в массиве. */
  public name: string;

  /** Имя поля, использумое при поиске (в ArcGIS совпадает с title). */
  public filterName: string;

  /** Имя поля, отображаемое для пользователя. */
  public title: string;

  /** Описание поля (отображается пользователю) */
  public description: string;

  /** Примеры значений, допустимых в поле (отображается пользователю). */
  public examples: string;

  /** Наборы, в которые включено поле (для переключения наборов полей при отображении).
   * Если groups = ["A", "B"], то поле отображается пользователю только тогда, когда он выберет группу(вкладку) A или B.
   */
  public groups: string[];

  /** Поле отображается как форматированный текст. */
  public formatted: boolean;

  /** Список групп пользователей, которы разрешен просмотр поля. Если пусто, то всем. */
  public allowReadGroups: string[];

  /** Список групп пользователей, которы разрешено редактирование поля. Если пусто, то всем. */
  public allowEditGroups: string[];

  /** Тип данных, допустимый в поле (string, number, date, dictionary).
   * Может использоваться для валидации.
   */
  public type: string;

  /** Если один элемент - то первый элемент используется как префикс для значения (при отображении добавляется перед значением поля).
   * Если два - то первый используется как строка замены для найденных сопоставлений, а второй - как поисковое регулярное выражение.
   */
  public urlPrefix: string[];

  /** Допустимые значения в поле (напр. словарные). */
  public possibleValues: any;

  /** Имя слоя, в котором находится поле. */
  public layer: string;

  /** Не выводить поле при запросе. */
  public hidden: boolean;

  getValuesFn: (value: any) => Promise<any[]>;

  constructor(configArray: [] = null) {
    if (configArray) {
      this.module = configArray['module'];
      this.name = configArray['name'];
      this.title = configArray['title'];
      this.description = configArray['description'];
      this.examples = configArray['examples'];
      this.groups = configArray['groups'];
      this.type = configArray['type'];
      this.possibleValues = configArray['possibleValues'];
      this.filterName = configArray['filterField'] ?? this.name;
      this.urlPrefix = configArray['urlPrefix'] ?? this.name;
    }
  }
}
