import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TreeComponent } from '@circlon/angular-tree-component';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { CatalogResource } from 'src/app/model/catalog-resource';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';
import { WegaCatalogNodeCollection } from 'src/app/model/wc-nodes';

export enum CopyNodeMode {
  none = 'none',
  anotherSite = 'anotherSite',
  externalCatalog = 'externalCatalog',
  Json = 'Json',
}

@Component({
  selector: 'app-site-resources',
  templateUrl: './site-resources.component.html',
  styleUrls: ['./site-resources.component.css'],
})
export class SiteResourcesComponent implements OnInit {
  @ViewChild(TreeComponent) private treeResourceListOfSite: TreeComponent;

  treeOptions = {
    allowDrag: true,
    allowDrop: true,
  };



  @Input() site: Site;
  importMode: CopyNodeMode = CopyNodeMode.none;
  selectedNodeForCopy: CatalogNode;
  allowEdit: boolean;
  resourceJson: string;

  constructor(
    private main: CatalogMainService,
    private router: Router,
    public config: WegaCatalogConfigService,
    public wcNodes: WegaCatalogNodeCollection,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.LoadResources();
    this.allowEdit = this.config.allowEditSite && this.site.allowEdit;
    this.treeOptions.allowDrag = this.allowEdit;
    this.treeOptions.allowDrop = this.allowEdit;

    this.wcNodes.$$treeUpdate.subscribe(t => {
      this.treeResourceListOfSite?.treeModel.update();
    })
  }

  AddRootNode() {
    var newResource = new CatalogNode();
    newResource.name = 'new';
    this.wcNodes.nodes.push(newResource);
    this.treeResourceListOfSite.treeModel.update();
    return newResource;
  }

  DuplicateNode() {
    var newResource = new CatalogNode();
    newResource.CopyFrom(this.wcNodes.selectedNode);
    this.wcNodes.nodes.push(newResource);
    this.treeResourceListOfSite.treeModel.update();
    return newResource;
  }

  DeleteSelectedReourceNode() {
    var activeTreeNode = this.treeResourceListOfSite.treeModel.getActiveNode();
    if (!activeTreeNode) {
      return;
    }
    var activeNode = activeTreeNode.data;
    var parentTreeNode = activeTreeNode.parent;

    /// Сначала определим откуда нужно удалить узел - из корневого списка или из детей другого узла
    var parentList = this.wcNodes.nodes;
    if (parentTreeNode) {
      parentList = parentTreeNode.data.children;
    }

    // затем из этого списка удаляем его.
    var index = parentList.indexOf(activeNode);
    if (index > -1) {
      parentList.splice(index, 1);
      this.treeResourceListOfSite.treeModel.update();
    }
  }

  async SelectNode(node: CatalogNode) {
    // Если создан новый ресурс - то его не будет в БД.
    this.main.CacheResource(node.id, node.resource);
    var loadedResource = await this.main.GetResource(this.site, node.id);
    if (
      loadedResource &&
      loadedResource.service &&
      loadedResource.service.length > 0
    ) {
      if (node.resource) {
        Object.assign(node.resource, loadedResource);
      } else {
        node.resource = loadedResource;
      }
    }

    this.wcNodes.selectedNode = node;
    this.importMode = CopyNodeMode.none;
  }

  async SaveResourceList() {
    this.wcNodes.nodes = await this.main.UpdateResourceList(this.site.code, this.wcNodes.nodes);
    await this.main.UpdateAllResources();

    this.snackBar.open('Список ресурсов сохранен', null, {
      duration: 5000,
    });
  }

  async LoadResources() {
    this.wcNodes.nodes = await this.main.GetResourceList(this.site);
    this.treeResourceListOfSite.treeModel.update();
  }

  ModeCopyFromAnotherSite() {
    this.importMode = CopyNodeMode.anotherSite;
    this.wcNodes.selectedNode = null;
  }

  ModeGetFromExternalCatalog() {
    this.importMode = CopyNodeMode.externalCatalog;
    this.wcNodes.selectedNode = null;
  }

  SelectNodeForCopy(node: CatalogNode) {
    this.selectedNodeForCopy = node;
  }

  CopyResourceToTree() {
    if (this.selectedNodeForCopy) {
      this.wcNodes.nodes.push(this.selectedNodeForCopy);
      this.treeResourceListOfSite.treeModel.update();

      this.selectedNodeForCopy = null;
    }
  }

  StartJsonEdit() {
    if (this.wcNodes.selectedNode) {
      this.importMode = CopyNodeMode.Json;
      this.resourceJson = JSON.stringify(this.wcNodes.selectedNode.resource, null, 3);
    }
  }

  CommitJsonEdit() {
    this.importMode = CopyNodeMode.none;
    Object.assign(this.wcNodes.selectedNode.resource, JSON.parse(this.resourceJson));
    this.main.CacheResource(this.wcNodes.selectedNode.id, this.wcNodes.selectedNode.resource);
    this.resourceJson = null;
  }

  RevertJsonEdit() {
    this.importMode = CopyNodeMode.none;
    this.resourceJson = null;
  }
}
