Список сервисов<br>

<button (click)="AddService()">Добавить</button>
<hr>
  <ul>
      <li *ngFor="let service of serviceList; index as i">
          <button (click)="DeleteService(i)"> x </button>
          <button (click)="SelectService(service)"> > </button>
          [ {{service.title}} ]
      </li>
  </ul>
<hr>
