<p>Список сайтов</p>

<table>
    <thead>
        <tr>
            <td></td>
            <td>Code</td>
            <td></td>
            <td>is public</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let site of FilterSites(sitesList)">
            <td>
                <button *ngIf="config.allowDeleteSite" (click)="DeleteSite(site)">X</button>
            </td>
            <td>
                <button (click)="SelectSite(site)">{{site.code}}</button>
            </td>
            <td>
                <input *ngIf="config.allowEditSite && site.allowEdit" [(ngModel)]="site.name">
                <span *ngIf="!config.allowEditSite || !site.allowEdit">{{site.name}}</span>
            </td>
            <td>
                <button *ngIf="config.allowEditSite && site.allowEdit" (click)="SaveSite(site)">V</button>
            </td>
            <td>
                {{site.isPublic ? "Y" : "N" }}
            </td>
        </tr>
        <tr *ngIf="config.allowAddSite">
            <td colspan="3">
                [code:<input [(ngModel)]="tmpCode">]
            </td>
            <td>
                <button (click)="AddSite()">
                  Add Site
                </button>
            </td>

        </tr>
    </tbody>


</table>
