export class WegaCatalogUser {
  public name: string;
  public email: number;
  public groupsList: string[];
  public isAuth: boolean;
  public isAdmin: boolean;

  constructor(params: any) {
    this.name = params['name'];
    this.email = params['email'];
    this.groupsList = params['groupsList'];
    this.isAuth = params['isAuth'];
    this.isAdmin = params['isAdmin'];
  }
}
