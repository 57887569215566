import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ITreeOptions,
  KEYS,
  TreeComponent,
} from '@circlon/angular-tree-component';
import { noop } from 'rxjs';
import { CatalogMainService } from 'src/app/model/catalog-main.service';
import { CatalogNode } from 'src/app/model/catalog-node';
import { GeoExtent } from 'src/app/model/catalog-service';
import { InitParams } from 'src/app/model/init-params';
import { Site } from 'src/app/model/site';
import { WegaCatalogConfigService } from 'src/app/model/wc-config.service';

@Component({
  selector: 'app-site-params',
  templateUrl: './site-params.component.html',
  styleUrls: ['./site-params.component.css'],
})
export class SiteParamsComponent implements OnInit {
  @ViewChild(TreeComponent) private treeStartupMaps: TreeComponent;

  selectResource: boolean = false;
  treeStartupMapsOptions: ITreeOptions = {
    displayField: 'name',
    actionMapping: {
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          var indx = this.initParams.StartupMaps.indexOf(node);
          this.initParams.StartupMaps.splice(indx);
          this.treeStartupMaps.treeModel.update();
        },
      },
    },
  };

  _site: Site;
  allowEdit: boolean;

  @Input()
  set site(val: Site) {
    this._site = val;
    this.Load();
  }

  get site(): Site {
    return this._site;
  }

  initParams: InitParams;

  constructor(
    private main: CatalogMainService,
    private config: WegaCatalogConfigService,
    private snackBar: MatSnackBar
  ) {}

  async Load() {
    this.initParams = {
      ...new InitParams(),
      ...(await this.main.GetSiteInit(this.site.code)),
    };
  }

  AddCenterParameter() {
    this.initParams.Center = [0, 0];
  }

  AddExtent() {
    var extent = new GeoExtent();
    this.initParams.Extent = {
      xmin: extent.xmin,
      xmax: extent.xmax,
      ymin: extent.ymin,
      ymax: extent.ymax,
    };
  }

  RemoveExtent() {
    this.initParams.Extent = void 0;
  }

  ShowSelectNode() {
    this.selectResource = true;
  }

  TurnoffStartupMaps() {
    delete this.initParams.StartupMaps;
  }

  async SelectNode(node: CatalogNode) {
    if (!this.initParams.StartupMaps) {
      this.initParams.StartupMaps = [];
    }

    var resourceData = await this.main.GetResource(this.site, node.id);

    Object.assign(node, resourceData);
    this.initParams.StartupMaps.push(node);

    this.selectResource = false;
    this.treeStartupMaps.treeModel.update();
  }

  async SaveInitParams() {
    this.main.UpdateSiteInit(this.site.code, this.initParams);

    this.snackBar.open('Конфигурация сайта сохранена', null, {
      duration: 5000,
    });
  }

  ngOnInit(): void {
    this.allowEdit = this.config.allowEditSite && this.site.allowEdit;
    this.treeStartupMapsOptions.allowDrag = this.config.allowEditSite && this.site.allowEdit;
    this.treeStartupMapsOptions.allowDrop = this.config.allowEditSite && this.site.allowEdit;
  }
}
