import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'div-input',
  templateUrl: './div-input.component.html',
  styleUrls: ['./div-input.component.css'],
})
export class DivInputComponent implements OnInit {
  _model: string;
  _afterInit: string;

  @ViewChild('span') span: ElementRef;

  @Input('value') set model(value: string) {
    if (value == undefined) {
      value = '';
    }
    this._afterInit = value; // для отложенной инициализации (ngAfterInit потом снова загружает)
    this._model = value;
    var currentValue = this.span?.nativeElement?.innerText;
    if (this.span?.nativeElement && currentValue !== this._model) {
      this.span.nativeElement.innerText = this._model;
    }
  }

  @Output('valueChange') modelChange = new EventEmitter<string>();

  @Input() edit: boolean = false;

  @Input() isNumeric: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ChangeValue(value: string) {
    if (this.isNumeric) {
      if (value[0] == '0') {
        value = value.substring(1);
      }

      if (value == undefined || value == null || value == '') {
        value = '';
      }

      var valueIsNumber = '' + parseFloat(value) == value;
      if (value && !valueIsNumber) {
        value = this._model;
        this.model = this._model;
      }
    }

    this._model = value;
    this.modelChange.emit(value);
  }

  ngAfterViewInit(): void {
    this.model = this._afterInit;
  }
}
